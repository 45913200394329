import React from 'react';
import { Flex } from '../../../../../utils/layout';
import styles from './itemList.module.css';

interface ItemListProps {
  children?: React.ReactNode;
  type?: 'point' | 'str' | 'icon';
  bold?: boolean;
  className?: string;
  str?: number | string;
  icon?: JSX.Element;
}

const ItemList: React.FC<ItemListProps> = ({
  children,
  type,
  bold,
  className,
  str,
  icon,
}) => {
  return (
    <Flex
      className={[
        className, // Clase pasada desde el padre
        styles.containerItem,
        bold && styles.bold,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {type === 'point' && <span>&#x2022;</span>}
      {type === 'str' && <span>{str}</span>}
      {type === 'icon' && <span>{icon}</span>}

      {children}
    </Flex>
  );
};

export default ItemList;
