import React from 'react';
import {
  statusFixture,
  StatusFixture,
} from '../../../../../types/fixture.types';
import { Flex } from '../../../../../utils/layout';

import styles from './itemList.module.css';
import { Tag } from '../../../../commons';
import { capitalizeFirstLetter } from '../../../../../utils';

type TItemTicket = {
  status: StatusFixture;
  name: string;
  onSelected: () => void;
  isSelected?: boolean;
};

export const colorStatusFixture: { [key in StatusFixture]: string } = {
  inProgress: 'green',
  toStart: '#f9ba15',
  finished: '#d6d3d3',
  created: '#9b9b9b',
};

const ItemList: React.FC<TItemTicket> = ({
  name,
  status,
  onSelected,
  isSelected,
}) => {
  return (
    <Flex
      className={`${styles.containerItemTicket} ${
        isSelected ? styles.selectedItem : ''
      }`}
      onClick={onSelected}
    >
      <Flex className={styles.nameText}>
        <p>{capitalizeFirstLetter(name)}</p>
      </Flex>

      <Tag className={`${styles.tag} ${styles[status]}`} size='small'>
        {statusFixture[status]}
      </Tag>
    </Flex>
  );
};

export default ItemList;
