import React from 'react';
import styles from './matchItem.module.css';
import {
  SimplePrediction,
  TOnChangePredictionProps,
} from '../../../../screens/home/components/fixtureSection/FixtureSection';
import { MatchAttributes, statusMatch } from '../../../../../types/match.types';
import useClientStore from '../../../../clientStore/useClientStore';
import formatDateTime from '../../../../../utils/hooks/formatDate';
import { validateStartDate } from '../../../../../utils/hooks/handleDate';
import { capitalizeFirstLetter, getMatchResult } from '../../../../../utils';
import { Column, Flex } from '../../../../../utils/layout';
import PulseLoader from 'react-spinners/PulseLoader';

interface MatchItem {
  prediction?: SimplePrediction;
  onChangePrediction?: (p: TOnChangePredictionProps) => void;
  match: MatchAttributes;
  isGolden?: boolean;
  onlyView?: boolean;
}

const MatchItem: React.FC<MatchItem> = ({
  prediction,
  onChangePrediction,
  match,
  isGolden,
  onlyView,
}) => {
  const { loggedUser } = useClientStore();
  const { predictionLocalTeam, predictionVisitingTeam } = prediction || {
    matchId: undefined,
    predictionLocalTeam: undefined,
    predictionVisitingTeam: undefined,
  };

  const localTeam = match?.localTeam;
  const visitingTeam = match?.visitingTeam;
  const { date, time } = match?.startDate
    ? formatDateTime(new Date(match.startDate))
    : formatDateTime(undefined);
  if (!localTeam || !visitingTeam) return null;

  if (!match) return null;

  const resultLocalTeam =
    match.resultLocalTeam != null ? match.resultLocalTeam : '-';
  const resultVisitingTeam =
    match.resultVisitingTeam != null ? match.resultVisitingTeam : '-';

  const predictionLocal =
    predictionLocalTeam != null ? predictionLocalTeam : '-';
  const predictionVisiting =
    predictionVisitingTeam != null ? predictionVisitingTeam : '-';

  const isMatchStarted =
    match.status === 'inProgress' || match.status === 'finished';
  const isMatchToStart = !isMatchStarted && validateStartDate(match.startDate);

  const { isExactResult, isRightResult } = getMatchResult({
    predictionLocalTeam,
    predictionVisitingTeam,
    resultLocalTeam: match.resultLocalTeam,
    resultVisitingTeam: match.resultVisitingTeam,
  });

  const matchWithoutDefinition = match?.localTeam?.name === 'por definirse';

  const handleChangePrediction = (
    increase: boolean,
    team: 'local' | 'visitor',
  ) => {
    if (onChangePrediction) {
      onChangePrediction({
        increase,
        matchId: match.id,
        team,
      });
    }
  };

  return (
    <Column className={styles.itemWithStatus}>
      <div
        className={`${styles.containerFixtureItem} ${isGolden && styles.containerFixtureItemGolden}`}
      >
        {/* TEAM LEFT */}
        <div className={`${styles.containerTeam}`}>
          <div>
            <img
              src={localTeam?.image}
              alt={`escudo-${localTeam.name}`}
              className={styles.imgTeam}
            />
          </div>
          <Column className={styles.teamLeft}>
            <Flex className={styles.buttonsCont}>
              {isMatchToStart && !matchWithoutDefinition && !onlyView ? (
                <div
                  className={styles.buttonPoints}
                  onClick={() => handleChangePrediction(false, 'local')}
                >
                  <i className='fa-solid fa-minus'></i>
                </div>
              ) : null}
              <span className={styles.resultNumber}>
                {predictionLocal != null ? predictionLocal : '-'}
              </span>
              {isMatchToStart && !matchWithoutDefinition && !onlyView ? (
                <div
                  className={styles.buttonPoints}
                  onClick={() => handleChangePrediction(true, 'local')}
                >
                  <i className='fa-solid fa-plus'></i>
                </div>
              ) : null}
            </Flex>
            <p className={styles.teamText}>
              {capitalizeFirstLetter(localTeam.name)}
            </p>
          </Column>
        </div>

        {/* MIDDLE */}
        {isMatchStarted ? (
          <Column alignItems='center' className={styles.containerMiddle}>
            <Flex
              className={`${styles.containerResult} ${isExactResult ? styles.exactResult : ''} ${isRightResult ? styles.rightResult : ''}`}
            >
              <span>{resultLocalTeam != null ? resultLocalTeam : ''}</span>
              <p>-</p>
              <span>{resultLocalTeam != null ? resultVisitingTeam : ''}</span>
            </Flex>
            {match?.status === 'inProgress' ? (
              <Flex>
                <PulseLoader color='#f9ba15' size={8} speedMultiplier={0.7} />
              </Flex>
            ) : null}
          </Column>
        ) : null}

        {/* TEAM RIGHT */}
        <div
          className={`${styles.containerTeam} ${styles.containerTeamRigth}  `}
        >
          <Column className={styles.teamRight}>
            <Flex className={styles.buttonsCont}>
              {isMatchToStart && !matchWithoutDefinition && !onlyView ? (
                <div
                  className={styles.buttonPoints}
                  onClick={() => handleChangePrediction(false, 'visitor')}
                >
                  <i className='fa-solid fa-minus'></i>
                </div>
              ) : null}
              <span className={styles.resultNumber}>
                {predictionVisiting != null ? predictionVisiting : '-'}
              </span>
              {isMatchToStart && !matchWithoutDefinition && !onlyView ? (
                <div
                  className={styles.buttonPoints}
                  onClick={() => handleChangePrediction(true, 'visitor')}
                >
                  <i className='fa-solid fa-plus'></i>
                </div>
              ) : null}
            </Flex>
            <p className={styles.teamText}>
              {capitalizeFirstLetter(visitingTeam.name)}
            </p>
          </Column>
          <img
            src={visitingTeam.image}
            alt={`escudo-${visitingTeam.name}`}
            className={styles.imgTeam}
          />
        </div>
      </div>
      <Flex
        className={`${styles.containerStatus} ${isGolden && styles.containerStatusGolden}`}
      >
        {match.stadium ? (
          <p>{`${capitalizeFirstLetter(match.stadium)} - `}</p>
        ) : null}
        {match.status === 'toStart' ? (
          <>
            <p>{date}</p>
            <p>{time}</p>
            <p>(GMT-3)</p>
          </>
        ) : null}
        {match.status === 'created' ? (
          <>
            <p>Por definirse</p>
          </>
        ) : null}
        {match.status !== 'toStart' && match.status !== 'created' ? (
          <>
            <p>{statusMatch[match.status]}</p>
            <p>-</p>
            <p>{date}</p>
          </>
        ) : null}
      </Flex>

      {/* CUADRO DE PUNTOS */}
      {loggedUser && isMatchStarted ? (
        <div
          className={`${styles.containerPoints} ${isExactResult ? styles.containerPointsExact : ''} ${isRightResult ? styles.containerPointsRight : ''}`}
        >
          {isExactResult ? '+2' : isRightResult ? '+1' : 0}
        </div>
      ) : null}
    </Column>
  );
};

export default MatchItem;
