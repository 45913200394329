export type CreditsPacksId = 'PA3000' | 'PA5000' | 'PA10000';

export type MpPreferenceResponse = {
  url: string;
  id: string;
};

export const bookCreditPacks: { [key in CreditsPacksId]: number } = {
  PA3000: 3420,
  PA5000: 5200,
  PA10000: 10000,
};
