import React from 'react';
import styles from './headerTable.module.css';
import { TicketName } from '../../../../modals/showPredictionsModal/ShowPredictionsModal';

type HeaderTableProps = {
  ticketName: TicketName;
};
const HeaderTable: React.FC<HeaderTableProps> = ({ ticketName }) => {
  return (
    <thead>
      <tr className={styles.header}>
        <th>#</th>
        <th className={styles.leftText}>Usuario</th>
        <th></th>
        <th>Puntos</th>
        {ticketName === 'fixtureTicket' && <th>Pos. Torneo</th>}
        <th>Ver</th>
      </tr>
    </thead>
  );
};

export default HeaderTable;
