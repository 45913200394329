import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';
import { TransactionAttributes } from '../../../../types/transaction.types';
import useClientStore from '../../../clientStore/useClientStore';

type IPostCreateWithdrawal = (
  body: TransactionAttributes,
) => Promise<TransactionAttributes>;

const postCreateWithdrawl: IPostCreateWithdrawal = async (body) => {
  const response = await axiosFetch('/transaction/withdrawal', {
    method: 'POST',
    data: body,
  });
  return response;
};

const useCreateWithdrawal: () => UseMutationResult<
  TransactionAttributes,
  any,
  TransactionAttributes,
  unknown
> = () => {
  const serverStore = useQueryClient();
  const { loggedUser } = useClientStore();
  const email = loggedUser?.email;

  return useMutation({
    mutationFn: postCreateWithdrawl,
    onSuccess: () => {
      serverStore.invalidateQueries({ queryKey: ['transactions', email] });
    },
  });
};

export default useCreateWithdrawal;
