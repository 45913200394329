import { Modal } from 'antd';
import React, { useState } from 'react';
import useWriting from '../../../copywriting/useWriting';
import LoginModal from './components/login/LoginModal';
import ForgetPassword from './components/forgetPassword/ForgetPassword';
import RegisterModal from './components/register/RegisterModal';
import { GoogleButton } from '../../commons';
import styles from './authModal.module.css';
import { Column, Flex } from '../../../utils/layout';
import { CloseOutlined } from '@ant-design/icons';

export type AuthType = 'login' | 'register' | 'forgotPassword';

interface IAuthModalProps {
  authType: AuthType;
  open: boolean;
  handleClose?: () => void;
}

const AuthModal: React.FC<IAuthModalProps> = ({
  authType,
  open,
  handleClose,
}) => {
  const writing = useWriting();
  const [auth, setAuth] = useState(authType);
  return (
    <>
      <Modal
        open={open}
        onCancel={handleClose}
        footer={null}
        className={styles.modal}
        closeIcon={false}
      >
        <Column className={styles.content}>
          <Flex className={styles.header}>
            {writing.user[auth].title}
            <div className={styles.closeIcon} onClick={handleClose}>
              <CloseOutlined />
            </div>
          </Flex>
          {auth === 'login' ? (
            <LoginModal setAuth={setAuth} handleClose={handleClose} />
          ) : null}
          {auth === 'register' ? (
            <RegisterModal setAuth={setAuth} handleClose={handleClose} />
          ) : null}
          {auth === 'forgotPassword' ? (
            <ForgetPassword setAuth={setAuth} />
          ) : null}
          {auth !== 'forgotPassword' ? (
            <GoogleButton handleClose={handleClose} />
          ) : null}
        </Column>
      </Modal>
    </>
  );
};

export default AuthModal;
