import React, { useEffect, useState } from 'react';
import { useGetAllFixtureTickets } from '../../../../../../serverStore/queries';
import { FixtureAttributes } from '../../../../../../../types/fixture.types';
import { validateStartDate } from '../../../../../../../utils/hooks/handleDate';
import useClientStore from '../../../../../../clientStore/useClientStore';
import PositionsTable from '../homePositionsTable/HomePositionsTable';
import ShowMoreFixturesTicket from './components/ShowMoreFixturesTicket';

interface IFixtureResultProps {
  fixture: FixtureAttributes;
}

const FixtureResult: React.FC<IFixtureResultProps> = ({ fixture }) => {
  const {
    fixtureSelected: { fixtureId },
  } = useClientStore();
  const selectedFixtureId = fixture?.id || fixtureId;

  const { data, refetch } = useGetAllFixtureTickets({
    groupped: true,
    page: '1',
    resultsPerPage: '10',
    fixtureId: selectedFixtureId ? Number(selectedFixtureId) : undefined,
    enabledRequest: !!selectedFixtureId,
  });
  const [openTableModal, setOpenTableModal] = useState(false);

  const isStarted = !validateStartDate(fixture?.startDate);

  const handleCloseTableModal = () => {
    setOpenTableModal(false);
  };

  const handleshowMoreTickets = () => {
    setOpenTableModal(true);
  };

  useEffect(() => {
    refetch();
  }, [selectedFixtureId]);

  if (!data?.results) return null;

  return (
    <>
      <PositionsTable
        isStarted={isStarted}
        grouppedTickets={data.results}
        totalTickets={data.count}
        onClickShowMoreTickets={handleshowMoreTickets}
        ticketName={'fixtureTicket'}
        currentPage={1}
      />

      {/* TABLA PARA MOSTRAR MAS TICKETS */}
      {openTableModal && fixture?.id ? (
        <ShowMoreFixturesTicket
          fixtureId={fixture?.id}
          openTableModal={openTableModal}
          onClose={handleCloseTableModal}
          isStarted={isStarted}
          fixtureName={fixture?.name}
        />
      ) : null}
    </>
  );
};

export default FixtureResult;
