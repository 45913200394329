import React from 'react';
import { FixtureAttributes } from '../../../../../types/fixture.types';
import { Column, Flex } from '../../../../../utils/layout';

import styles from './resultSection.module.css';
import FixtureResult from './components/fixtureResult/FixtureResult';
import { TournamentWithFixtureName } from '../../../../../types/tournament.types';
import TournamentResult from './components/tournamentResult/TournamentResult';
import { ButtonsGroup, Divider } from '../../../../commons';
import PositionsTable from './components/homePositionsTable/HomePositionsTable';
import { OptionButtonGroup } from '../../../../commons/buttonsGroup/ButtonsGroup';
import { capitalizeFirstLetter } from '../../../../../utils';

type TResultSection = {
  fixture?: FixtureAttributes;
  tournament?: TournamentWithFixtureName;
};
const ResultSection: React.FC<TResultSection> = ({ fixture, tournament }) => {
  const [tableSelected, setTableSelected] = React.useState('fixture');
  const handleButtons = (op: OptionButtonGroup) => {
    console.log('CONSOLE -ResultSection- ', { op });
    setTableSelected(op.value);
  };

  return (
    <div className={styles.containerResultSection}>
      <Divider position='horizontal' />
      <Column className={styles.containerPositions}>
        <span className={styles.title}>Tabla de Posiciones</span>
        <ButtonsGroup
          onClick={handleButtons}
          classNameButton={styles.buttonGroup}
          options={[
            {
              value: 'fixture',
              label: capitalizeFirstLetter(fixture?.name) || '',
            },
            {
              value: 'tournament',
              label: capitalizeFirstLetter(tournament?.name) || '',
            },
          ]}
        />
      </Column>

      <Column className={styles.containerTables}>
        {fixture && tableSelected === 'fixture' ? (
          <FixtureResult fixture={fixture} />
        ) : null}
        {tournament && tableSelected === 'tournament' ? (
          <TournamentResult tournament={tournament} />
        ) : null}
      </Column>
    </div>
  );
};

export default ResultSection;
