import React, { useMemo } from 'react';
import styles from './withdrawlSection.module.css';
import { Column } from '../../../../../../utils/layout';
import { Button, Card, Divider } from '../../../../../commons';
import { Form, Input } from 'antd';
import { formatPrice, rulesForm } from '../../../../../../utils';
import WithdrawlModal from './components/WithdrawlModal';
import useClientStore from '../../../../../clientStore/useClientStore';
import { useCreateWithdrawal } from '../../../../../serverStore/mutations';
import { OptionButtonGroup } from '../../../../../commons/buttonsGroup/ButtonsGroup';

export type WithdrawlForm = {
  creditAmount: number;
  cbu: string;
  bankName: string;
  dni: number;
};

type Props = {
  handleButtons: (op: OptionButtonGroup) => void;
};
const WithdrawlSection: React.FC<Props> = ({ handleButtons }) => {
  const { mutate } = useCreateWithdrawal();
  const [confirmModal, setConfirmModal] = React.useState(false);
  const { loggedUser } = useClientStore();
  const [withdrawlForm] = Form.useForm<WithdrawlForm>();
  const rules = rulesForm();

  const amount = Form.useWatch('creditAmount', withdrawlForm);
  const numberAmount = amount != undefined ? Number(amount) : undefined;

  const isValidAmount = useMemo(() => {
    if (numberAmount !== undefined) {
      return numberAmount >= 1000 && numberAmount <= 20000;
    }
    return false;
  }, [numberAmount]);

  const onFinish = () => {
    setConfirmModal(true);
  };

  const handleSubmit = () => {
    withdrawlForm.submit();
  };

  const handleClickConfirm = () => {
    mutate(
      { ...withdrawlForm.getFieldsValue(), type: 'withdrawal' },
      {
        onSuccess: () => {
          setConfirmModal(false);
          withdrawlForm.resetFields();
          handleButtons({ value: 'history', label: 'Historial' });
        },
      },
    );
  };

  if (!loggedUser?.credits) {
    return (
      <Column className={styles.container}>
        <span className={styles.title}>
          Actualmente no tienes créditos disponibles para retirar.
        </span>
      </Column>
    );
  }

  return (
    <Column className={styles.container}>
      <span className={styles.title}>
        Retira tus créditos en el momento que quieras
      </span>
      <span className={styles.description}>
        Puedes retirar los créditos disponibles en cualquier momento. Solo
        ingresa la cantidad de créditos que deseas retirar, los datos de tu
        cuenta bancaria donde quieres recibir tu dinero y listo, ¡así de simple!
      </span>

      <Card className={styles.card}>
        <span>Completa el Formulario de Retiro</span>
        <Form
          layout={'vertical'}
          form={withdrawlForm}
          onFinish={onFinish}
          name='withdrawlForm'
          className={styles.form}
        >
          <Form.Item
            name='creditAmount'
            rules={[...rules.required]}
            label='Créditos a retirar'
          >
            <Input
              placeholder='Ingresa la cantidad de créditos'
              type='number'
              className={styles.input}
            />
          </Form.Item>
          <Column className={styles.containerAmount}>
            {isValidAmount ? (
              <span className={styles.depositAmount}>
                Vas a recibir: {formatPrice(numberAmount)}
              </span>
            ) : null}
            {numberAmount != undefined && !isValidAmount ? (
              <span className={styles.errorAmount}>
                La cantidad de créditos debe estar entre 1000 y 20000
              </span>
            ) : null}
          </Column>

          <Form.Item name='bankName' rules={rules.required} label='Banco'>
            <Input
              placeholder={'Ingresa el nombre de tu Banco'}
              className={styles.input}
            />
          </Form.Item>

          <Form.Item
            name='cbu'
            rules={[
              ...rules.required,
              {
                pattern: /^\d{22,}$/,
                message: 'El CBU/CVU debe contener 22 números.',
              },
            ]}
            label='CBU/CVU'
          >
            <Input
              placeholder={'Ingresa tu CBU/CVU'}
              className={styles.input}
              maxLength={22}
            />
          </Form.Item>

          <Form.Item
            name='dni'
            rules={[
              ...rules.required,
              {
                pattern: /^\d{8,}$/,
                message: 'El DNI debe contener 8 números.',
              },
            ]}
            label='DNI'
          >
            <Input
              placeholder={'Ingresa tu DNI'}
              type='number'
              className={styles.input}
              maxLength={8}
            />
          </Form.Item>

          <Button
            loading={false}
            type='primary'
            onClick={handleSubmit}
            className={styles.button}
          >
            Retirar Créditos
          </Button>

          <Divider className={styles.divider} />
          <Column>
            <span className={styles.note}>
              &#x2022; Los retiros normalmente se realizan en un plazo de 2
              horas, pero dependiendo de la demanda pueden demorar hasta 48
              horas en impactar en tu cuenta.
            </span>
            <span className={styles.note}>
              &#x2022; Si tienes problemas durante el proceso, no dudes en
              comunicarte con nuestro soporte.
            </span>
            <span className={styles.note}>
              &#x2022; Puedes realizar retiros de créditos las veces que desees,
              con un límite máximo de 20.000 créditos por transacción.
            </span>
          </Column>
        </Form>
      </Card>

      {confirmModal && (
        <WithdrawlModal
          onClose={() => setConfirmModal(false)}
          open={confirmModal}
          values={withdrawlForm.getFieldsValue()}
          onClickConfirm={handleClickConfirm}
        />
      )}
    </Column>
  );
};

export default WithdrawlSection;
