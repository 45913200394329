import React from 'react';
import styles from './homePositionsTable.module.css';
import { GroupedTickets } from '../../../../../../../types/ticket.types';
import { TicketName } from '../../../../../../modals/showPredictionsModal/ShowPredictionsModal';

import useClientStore from '../../../../../../clientStore/useClientStore';
import { Flex } from '../../../../../../../utils/layout';
import { CustomTooltip, PositionsTable } from '../../../../../../commons';

type PositionsTableProps = {
  grouppedTickets: GroupedTickets;
  totalTickets: number;
  onClickShowMoreTickets?: () => void;
  isStarted: boolean;
  ticketName: TicketName;
  currentPage: number;
};

const HomePositionsTable: React.FC<PositionsTableProps> = ({
  grouppedTickets,
  totalTickets,
  onClickShowMoreTickets,
  isStarted,
  ticketName,
  currentPage,
}) => {
  const { loggedUser } = useClientStore();

  const handleShowMoreTickets = () => {
    if (loggedUser) {
      onClickShowMoreTickets?.();
    }
  };

  return (
    <div className={styles.tableContainer}>
      <PositionsTable
        currentPage={currentPage}
        grouppedTickets={grouppedTickets}
        isStarted={isStarted}
        ticketName={ticketName}
      />

      {/* Footer */}
      {totalTickets > 10 ? (
        <Flex className={styles.footer}>
          <p>{totalTickets} Fixtures en total</p>
          <p>-</p>
          <CustomTooltip
            title={'Inicia sesion para ver'}
            disabled={!!loggedUser}
          >
            <p
              className={styles.showMoreTickets}
              onClick={handleShowMoreTickets}
            >
              Ver todos
            </p>
          </CustomTooltip>
        </Flex>
      ) : null}
    </div>
  );
};

export default HomePositionsTable;
