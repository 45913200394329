import React from 'react';
import { capitalizeFirstLetter } from '../../../utils';
import { Column } from '../../../utils/layout';
import useClientStore from '../../clientStore/useClientStore';
import { useGetTournamentNames } from '../../serverStore/queries';
import ItemList from './components/itemList/ItemList';
import styles from './stylesModal.module.css';
import { DarkModal } from '../../commons';

interface IModalProps {
  open?: boolean;
  onClose?: () => void;
}

const TournamentsListModal: React.FC<IModalProps> = ({ open, onClose }) => {
  const { data: tournamentsData } = useGetTournamentNames();
  const {
    dispatch,
    fixtureSelected: { tournamentId },
  } = useClientStore();

  const onSelectedItem = (id: number) => {
    dispatch({ type: 'SET_TOURNAMENT_ID', payload: id });
  };
  return (
    <DarkModal open={open} onClose={onClose} title='Lista de Torneos'>
      <Column className={styles.content}>
        {tournamentsData?.length
          ? tournamentsData.map((t) => {
              return (
                <ItemList
                  key={t.id}
                  status={t.status}
                  name={capitalizeFirstLetter(t.name) || t.name}
                  onSelected={() => onSelectedItem(t.id)}
                  isSelected={tournamentId === t.id}
                />
              );
            })
          : null}

        {tournamentsData?.length && tournamentsData?.length > 1 ? (
          <span className={styles.description}>
            Presiona sobre el Torneo para cambiar
          </span>
        ) : null}
      </Column>
    </DarkModal>
  );
};

export default TournamentsListModal;
