import React from 'react';
import styles from './divider.module.css';

type DividerProps = {
  position?: 'horizontal' | 'vertical';
  className?: string;
  color?: string;
};

const Divider: React.FC<DividerProps> = ({ className, color, position }) => {
  return (
    <div
      className={`${styles.divider} ${styles[position || 'horizontal']} ${className}`}
      style={{ color }}
    />
  );
};

export default Divider;
