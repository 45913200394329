import { Modal } from 'antd';
import React from 'react';
import useWriting from '../../../../../copywriting/useWriting';
import { useLogout } from '../../../../serverStore/mutations';
import useClientStore from '../../../../clientStore/useClientStore';
import styles from './logoutModal.module.css';
import { Column, Flex } from '../../../../../utils/layout';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from '../../../../commons';

type LogoutModalProps = {
  open: boolean;
  onClose: () => void;
};

const LogoutModal: React.FC<LogoutModalProps> = ({ open, onClose }) => {
  const writing = useWriting();
  const { mutate } = useLogout();
  const { dispatch } = useClientStore();

  const onConfirm = () => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        message: 'Usuario desconectado',
        status: 'info',
      },
    });
    mutate();
    onClose();
  };

  return (
    <Modal open={open} className={styles.modal} closeIcon={false} footer={null}>
      <Column className={styles.content}>
        <Flex className={styles.header}>
          {writing.authHeader.confirmModal.title}
          <div className={styles.closeIcon} onClick={onClose}>
            <CloseOutlined />
          </div>
        </Flex>
        <p>{writing.authHeader.confirmModal.description}</p>
        <Flex className={styles.buttons}>
          <Button type='secondary' size='small' onClick={onClose}>
            {writing.authHeader.confirmModal.cancelButton}
          </Button>
          <Button size='small' onClick={onConfirm}>
            {writing.authHeader.confirmModal.confirmButton}
          </Button>
        </Flex>
      </Column>
    </Modal>
  );
};

export default LogoutModal;
