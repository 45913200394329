import React from 'react';
import styles from './tag.module.css';
interface ITagProps {
  label?: string;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
}

const Tag: React.FC<ITagProps> = ({
  label,
  children,
  onClick,
  className,
  size,
}) => {
  const onTagClick = () => {
    onClick?.();
  };

  return (
    <div
      className={`${className && className} ${styles.tag} ${size && styles[size]}`}
      onClick={onTagClick}
    >
      {children || label}
    </div>
  );
};

export default Tag;
