import { useEffect } from 'react';
import useMatchUpdateHandler from './utils/useMatchUpdateHandler';
import useClientStore from '../../clientStore/useClientStore';
import { SocketMessageEvent } from '../../../types/sockets.types';
import useCreditUpdateHandler from './utils/useCreditUpdateHandler';

const useWebSocketHandler = (webSocketUrl: string) => {
  const handleMatchUpdate = useMatchUpdateHandler();
  const handleCreditUpdate = useCreditUpdateHandler();
  const { loggedUser } = useClientStore();

  useEffect(() => {
    const ws = new WebSocket(webSocketUrl);

    ws.onopen = () => {
      console.log('Conexión abierta.');
    };

    ws.onmessage = (mes) => {
      const message = JSON.parse(mes.data);
      const event: SocketMessageEvent = message.event;
      if (event === 'matchUpdated') {
        handleMatchUpdate(message.data);
      }
      if (event === 'creditUpdated') {
        handleCreditUpdate(message.data);
      }
    };

    ws.onclose = () => {
      console.log('Conexión cerrada.');
    };

    return () => {
      ws.close();
    };
  }, [webSocketUrl, loggedUser]);
};

export default useWebSocketHandler;
