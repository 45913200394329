import React from 'react';
import { Column } from '../../../utils/layout';
import styles from './card.module.css';

type CardProps = {
  children: React.ReactNode;
  className?: string;
};

const Card: React.FC<CardProps> = ({ children, className }) => {
  return <Column className={`${styles.card} ${className}`}>{children}</Column>;
};

export default Card;
