import React from 'react';
import styles from './fixtureTitleItem.module.css';

type Props = {
  isStarted?: boolean;
  isGolden?: boolean;
};

const FixtureTitleItem: React.FC<Props> = ({ isStarted, isGolden }) => {
  return (
    <div
      className={`${styles.containerFixtureItem} ${isGolden && styles.golden}`}
    >
      <div className={styles.containerTeam}>LOCAL</div>

      {isStarted ? <div className={styles.containerTeam}>RESULTADO</div> : null}

      <div className={styles.containerTeam}>VISITANTE</div>
    </div>
  );
};

export default FixtureTitleItem;
