import React from 'react';
import styles from './button.module.css';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CustomSpin = () => {
  const customIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: 'black' }} /* Cambia el tamaño y color */
      spin
    />
  );

  return <Spin indicator={customIcon} />;
};

interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  type?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  className,
  onClick,
  loading,
  type,
  size,
}) => {
  return (
    <div
      className={`${className ? className : ''} ${size ? styles[size] : ''} ${styles.button} ${disabled ? styles.disabled : ''}  ${type ? styles[type] : ''} `}
      onClick={disabled ? undefined : onClick}
    >
      {loading ? <CustomSpin /> : children}
    </div>
  );
};

export default Button;
