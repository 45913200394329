import { CreditsPacksId } from './credit.types';
import { UserAttributes } from './user.types';

export type StatusTransaction =
  | 'pending'
  | 'approved'
  | 'rejected'
  | 'refunded'
  | 'cancelled'
  | 'in_progress'
  | 'charged_back';

export type TypeTransaction = 'deposit' | 'withdrawal';
export type PackDepositId = 'PA3000' | 'PA5000' | 'PA10000';

export interface TransactionAttributes {
  id?: number;
  userId?: string;
  creditAmount: number;
  type: TypeTransaction;
  status?: StatusTransaction;
  user?: UserAttributes;

  packId?: PackDepositId;
  paymentId?: string;
  price?: number;

  bankName?: string;
  dni?: number;
  cbu?: string;
  createdAt?: Date;
}

export const bookTypeTransaction: { [key in TypeTransaction]: string } = {
  deposit: 'Carga',
  withdrawal: 'Retiro',
};

export const bookStatusTransaction: { [key in StatusTransaction]: string } = {
  pending: 'Pendiente',
  approved: 'Completado',
  rejected: 'Rechazado',
  refunded: 'Reembolsado',
  cancelled: 'Cancelado',
  in_progress: 'En curso',
  charged_back: 'Cargado',
};
