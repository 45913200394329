import React from 'react';
import { Column, Flex } from '../../../../../../../utils/layout';
import { Switch } from 'antd';
import styles from './goldSwitch.module.css';

type GoldSwitchProps = {
  checked?: boolean;
  price?: number;
  onChange?: (checked: boolean) => void;
  currentTicketType?: 'golden' | 'normal';
};

const GoldSwitch: React.FC<GoldSwitchProps> = ({
  checked,
  price,
  onChange,
  currentTicketType,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Column className={`${styles.container} ${checked && styles.active}`}>
      {currentTicketType !== 'golden' && !checked ? (
        <>
          <Flex className={styles.titleContainer} onClick={handleOpen}>
            <span className={styles.title}>
              ¡Convierte tu Fixture en Golden!
            </span>
            {open ? (
              <i className='fa-solid fa-chevron-down'></i>
            ) : (
              <i className='fa-solid fa-chevron-up'></i>
            )}
          </Flex>

          {open && (
            <>
              <span className={styles.description}>
                Participa por créditos exclusivos transformando tu fixture en
                Golden.
              </span>

              <Switch checked={checked} onChange={onChange} />
              <span className={styles.importantText}>
                <strong>*Recuerda:</strong> Tienes hasta el inicio del primer
                partido para unirte a la competencia Golden. ¡Hazlo ahora y
                destaca entre los mejores!
              </span>
            </>
          )}
        </>
      ) : null}

      {currentTicketType !== 'golden' && checked && (
        <>
          <span className={styles.title}>
            ¡Haz brillar tu Fixture con el modo Golden!
          </span>
          <span className={styles.description}>
            Participa por premios exclusivos y compite al más alto nivel.
          </span>

          <Switch checked={checked} onChange={onChange} />

          <span className={styles.importantText}>
            <strong>*Atención:</strong> Esta acción descontará{' '}
            <strong>{price || 1000} créditos</strong> de tu cuenta.
          </span>
        </>
      )}

      {currentTicketType === 'golden' && (
        <>
          <Flex className={styles.titleContainer} onClick={handleOpen}>
            <span className={styles.title}>
              ¡Estás en la competencia Golden!
            </span>
            {open ? (
              <i className='fa-solid fa-chevron-down'></i>
            ) : (
              <i className='fa-solid fa-chevron-up'></i>
            )}
          </Flex>
          {open && (
            <span className={styles.description}>
              Tu Fixture Golden está activo. Estás participando por los créditos
              exclusivos de esta fecha. ¡Prepárate para destacar y ganar!
            </span>
          )}
          {/*         <span className={styles.importantText}>
            Créditos restantes en tu cuenta:{' '}
            <strong>{loggedUser?.credits || 0}</strong>.
          </span> */}
        </>
      )}
    </Column>
  );
};

export default GoldSwitch;
