import React from 'react';
import { DarkModal } from '../../../../../../../commons';
import { Column } from '../../../../../../../../utils/layout';
import styles from './historyDetailModal.module.css';
import { bookStatusTransaction } from '../../../../../../../../types/transaction.types';

type HistoryDetailModalProps = {
  open: boolean;
  onClose: () => void;
};

const HistoryDetailModal: React.FC<HistoryDetailModalProps> = ({
  onClose,
  open,
}) => {
  const data: any = {
    id: 'TX987659',
    date: '2024-11-27 21:20',
    type: 'Carga',
    amount: 3500,
    status: 'canceled',
    bankName: 'Banco de la Nacion Argentina',
    cbu: '123456789101112131415',
    dni: '12345678',
    reason: 'No se pudo completar la transaccion',
  };

  return (
    <DarkModal open={open} onClose={onClose} title='Detalles de la Transacción'>
      <Column className={styles.container}>
        <span>
          Transaccion ID: <strong>{data.id} </strong>
        </span>
        <span>
          Fecha: <strong>{data.date}</strong>
        </span>
        <span>
          Cantidad: <strong>{data.amount}</strong>
        </span>
        <span>
          Nombre del banco: <strong>{data.bankName}</strong>
        </span>
        <span>
          CBU/CVU de destino: <strong>{data.cbu}</strong>
        </span>
        <span>
          DNI: <strong>{data.dni}</strong>
        </span>
        <span>
          Estado: <strong>asddsa</strong>
        </span>
        {data.reason && (
          <span className={styles.importantText}>
            <strong>Motivo:</strong> {data.reason}
          </span>
        )}
      </Column>
    </DarkModal>
  );
};

export default HistoryDetailModal;
