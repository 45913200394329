import { useQueryClient } from '@tanstack/react-query';

type MatchUpdateData = {
  roundId: number;
  matchId: number;
  resultLocalTeam: number;
  resultVisitingTeam: number;
  status: string;
};

const useMatchUpdateHandler = () => {
  const queryClient = useQueryClient();

  const handleMatchUpdate = (updateData: MatchUpdateData) => {
    const { roundId, matchId, resultLocalTeam, resultVisitingTeam, status } =
      updateData;

    // Obtener todas las claves en la caché
    const cacheKeys = queryClient
      .getQueryCache()
      .findAll({ queryKey: ['round'] });

    // Filtrar las claves que coincidan con el roundId
    cacheKeys.forEach((cacheKey) => {
      if (cacheKey.queryKey.includes(roundId)) {
        queryClient.setQueryData(cacheKey.queryKey, (oldRound: any) => {
          if (!oldRound) return oldRound;

          // Actualiza el match en la lista de matches
          const updatedMatches = oldRound.matches.map((match: any) =>
            match.id === matchId
              ? {
                  ...match,
                  resultLocalTeam,
                  resultVisitingTeam,
                  status,
                }
              : match,
          );

          return {
            ...oldRound,
            matches: updatedMatches,
          };
        });
      }
    });

    queryClient.invalidateQueries({ queryKey: ['tickets-fixture'] });
  };

  return handleMatchUpdate;
};

export default useMatchUpdateHandler;
