import React from 'react';

import { Column, Flex } from '../../../../utils/layout';
import styles from './userCredits.module.css';
import { Button, ButtonsGroup, Card, Divider } from '../../../commons';
import useClientStore from '../../../clientStore/useClientStore';
import DepositSection from './components/depositSection/DepositSection';
import WithdrawlSection from './components/withdrawlSection/WithdrawlSection';
import HistorySection from './components/historySection/HistorySection';
import { OptionButtonGroup } from '../../../commons/buttonsGroup/ButtonsGroup';

const UserCredits: React.FC = () => {
  const { loggedUser } = useClientStore();
  const [buttonSelected, setButtonSelected] = React.useState<string>('deposit');

  const handleButtons = (op: OptionButtonGroup) => {
    setButtonSelected(op.value);
  };

  return (
    <Column className={styles.container}>
      <Card className={styles.card}>
        <span className={styles.title}>Administra tus créditos</span>
        <Column className={styles.credits}>
          <i className='fas fa-coins'></i>
          <div>{loggedUser?.credits || 0} Creditos</div>
        </Column>

        <ButtonsGroup
          onClick={handleButtons}
          options={[
            { value: 'deposit', label: 'Cargar' },
            { value: 'withdrawal', label: 'Retirar' },
            { value: 'history', label: 'Historial' },
          ]}
        />
      </Card>
      <Divider className={styles.divider} />
      {buttonSelected === 'deposit' && <DepositSection />}
      {buttonSelected === 'withdrawal' && (
        <WithdrawlSection handleButtons={handleButtons} />
      )}
      {buttonSelected === 'history' && <HistorySection />}
    </Column>
  );
};

export default UserCredits;
