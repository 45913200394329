import React, { useEffect, useRef, useState } from 'react';
import useWriting from '../../../copywriting/useWriting';
import { Column } from '../../../utils/layout';
import BannerSection from '../../../utils/layout/components/bannerSection/BannerSection';
import styles from './instructions.module.css';
import { Divider } from '../../commons';
import ItemList from './components/instructionItemList/ItemList';
import { deserialize } from 'v8';
import { Segmented } from 'antd';
import useClientStore from '../../clientStore/useClientStore';
import { useScreenResize } from '../../../utils';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
const howPlay = [
  {
    title: 'Crea tu cuenta o inicia sesión.',
    description:
      'Si aun no lo has hecho, registrate en la plataforma para empezar a jugar.',
  },
  {
    title: 'Accede al Fixture.',
    description:
      'Entra en la seccion del torneo activo y predice los partidos de la fecha.',
  },
  {
    title: 'Haz tus predicciones.',
    descriptions: [
      'Ingresa el resultado para cada partido antes de que comience.',
      'Puedes modificar tus predicciones hasta 5 minutos antes del inicio de cada partido.',
    ],
  },
  {
    title: 'Acumula puntos.',
    descriptions: [
      'Predicción exacta: Suma 2 puntos (ejemplo: predices 2-1 y el resultado es 2-1).',
      'Predicción acertada: Suma 1 punto (ejemplo: predices 2-1, pero el resultado es 3-2).',
    ],
  },
  {
    title: 'Gana premios.',
    description: 'El jugador con mas puntos en la fecha sera el ganador.',
  },
];

const generalRules = [
  'Solo puedes predecir resultados hasta 5 minutos antes del inicio de cada partido.',
  'Los partidos que se suspendan y se reprogramen para fechas fuera del rango actual no contarán para los puntos de la fecha.',
  'Participar es totalmente transparente: ¡tus predicciones y las de los demás jugadores serán visibles en tu perfil y en la tabla de posiciones de la fecha!',
  'El ganador de la fecha será el usuario que acumule la mayor cantidad de puntos en la totalidad de partidos de la fecha.',
  'Si el fixture es golden, el usuario ganador se llevará todos los créditos acumulados como premio.',
  'Si el fixture no es golden, el usuario ganador recibirá un premio fijo de 250 créditos.',
];

const goldenFixtures = [
  {
    title:
      'Antes de que inicie el primer partido de la fecha (hasta 5 minutos antes), puedes activar el Fixture Golden.',
    descriptions: [
      'Costo para activar: 1000 créditos.',
      'Premio acumulado: Por cada usuario que active el Fixture Golden, se suman 1000 créditos al premio total.',
    ],
  },
  {
    title: '¿Quién gana el Fixture Golden?',
    descriptions: [
      'Si el ganador de la fecha tiene un Fixture Golden activado, se lleva todo el premio acumulado.',
      'Si el ganador de la fecha no tiene un Fixture Golden, el premio acumulado irá al siguiente usuario con más puntos que sí haya activado el Fixture Golden.',
    ],
  },
  {
    title: 'Empates:',
    descriptions: [
      'En caso de empate, el premio acumulado se divide en partes iguales entre los ganadores.',
    ],
  },
  {
    title: 'Premio mínimo garantizado:',
    descriptions: [
      'Si el ganador no tiene Fixture Golden, recibirá 250 créditos como premio por ganar la fecha.',
    ],
  },
  {
    title: 'Plazo para recibir premios:',
    descriptions: [
      'Los créditos se acreditarán en tu cuenta de manera inmediata en la mayoría de los casos, aunque el proceso podría tardar hasta un máximo de 48 horas.',
    ],
  },
];

const reviewResults = [
  {
    title: 'Tabla de posiciones:',
    descriptions: [
      'Debajo de cada fixture encontrarás una tabla con las posiciones actuales tanto del Torneo como de la fecha.',
      'Resultados del fixture actual: Visualiza quién lleva la delantera en la fecha actual.',
      'Acumulado del torneo: Revisa el desempeño de cada participante en todo el torneo.',
    ],
  },
  {
    title: 'Detalle de predicciones',
    descriptions: [
      'Haz clic en el botón VER junto al nombre de un usuario.',
      'Visualizas sus predicciones en el fixture correspondiente.',
      'También puedes ver cuántos puntos sumó en cada partido del fixture.',
    ],
  },
];

const changeTournament = [
  {
    title: 'Cambiar de Torneo:',
    descriptions: [
      'Puedes cambiar de torneo en cualquier momento.',
      'En la sección principal, haz clic en el nombre del torneo actual que aparece destacado en la parte superior.',
      'Podrás ver el estado actual de cada torneo (activo, en curso, finalizado, etc.).',
      'Tambien puedes seleccionar un torneo para obtener más detalles, resultados o participar si el torneo está disponible.',
    ],
  },
  {
    title: 'Cambiar de Fecha:',
    descriptions: [
      'Puedes cambiar de fecha en cualquier momento.',
      'En la misma sección principal, haz clic en el nombre de la fecha actual del torneo.',
      'Podrás ver el estado actual de cada fecha (activa, en curso, finalizada, etc.).',
      'Tambien puedes seleccionar otra fecha para obtener más detalles, resultados o participar si la fecha esta disponible.',
    ],
  },
];

const depositCredits = [
  {
    title: 'Carga mínima:',
    descriptions: ['La carga mínima es de 1000 créditos por transacción.'],
  },
  {
    title: 'Tiempos de acreditación:',
    descriptions: [
      'Los créditos se cargarán al instante en tu cuenta.',
      'Dependiendo del método de pago utilizado, la acreditación puede demorar hasta 48 horas hábiles.',
    ],
  },
  {
    title: 'Cómo cargar créditos:',
    descriptions: [
      'Ve a la sección "Créditos" en tu cuenta y selecciona "Cargar Créditos.',
      'Selecciona el método de pago preferido y sigue los pasos indicados.',
      'Una vez procesado el pago, los créditos se reflejarán en tu balance.',
    ],
  },
];

const withdrawCredits = [
  {
    title: 'Retiro mínimo:',
    descriptions: [
      'Puedes retirar créditos de tu cuenta cuando quieras, pero el retiro mínimo es de 1000 créditos.',
    ],
  },
  {
    title: 'Tiempos de acreditación:',
    descriptions: [
      'Una vez solicitado el retiro, los créditos serán transferidos a tu cuenta bancaria en un plazo máximo de 48 horas hábiles.',
    ],
  },
  {
    title: 'Cómo retirar créditos:',
    descriptions: [
      'Ve a la sección "Créditos" en tu cuenta y selecciona "Retirar Créditos.',
      'Ingresa la cantidad a retirar (mínimo 1000 créditos).',
      'Proporciona los datos de la cuenta de retiro solicitados y confirma el retiro.',
    ],
  },
];

const navBook: { [key: string]: string } = {
  howPlay: 'Como participar',
  generalRules: 'Reglas generales',
  goldenFixtures: 'Fixture Golden',
  reviewResults: 'Revisar resultados',
  changeTournament: 'Cambiar de torneo o fixture',
  depositCredits: 'Cargar créditos',
  withdrawCredits: 'Retirar créditos',
};
const navOptions = Object.keys(navBook);

const Instructions: React.FC = () => {
  const writing = useWriting();
  const { isDesktop } = useScreenResize();
  const segmentedRef = useRef<HTMLDivElement>(null); // Reference for the Segmented component
  const [isFixed, setIsFixed] = useState(false);
  const [openMobileOptions, setOpenMobileOptions] = useState(false);
  const [optionChecked, setOptionChecked] = useState(navOptions[0]);

  console.log('CONSOLE -Instructions- ', { isFixed });

  const handleNavigation = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 230; // Altura del header
      const elementPosition = element.getBoundingClientRect().top; // Posición del elemento relativo a la ventana
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
      setOptionChecked(id);
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (segmentedRef.current) {
        const offsetTop = segmentedRef.current.offsetTop;
        const scrollY = window.scrollY;

        // Check if the scroll position has passed the Segmented component
        setIsFixed(scrollY > offsetTop);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Column className={styles.container}>
      <BannerSection nameSection={writing.instructions.title} />
      <div className={styles.containerInstructions}>
        <span className={styles.welcomeText}>¡Bienvenido a Fixtuwin!</span>
        <span className={styles.descriptionText}>
          ¿Listo para poner a prueba tu conocimiento de futbol y competir por
          increíbles premios? Aquí te explicamos cómo jugar y aprovechar al
          máximo esta experiencia.
        </span>

        {isDesktop ? (
          <div
            ref={segmentedRef}
            className={isFixed ? styles.segmentedFixed : styles.segmented}
          >
            <Segmented<string>
              options={navOptions.map((o) => {
                return { label: navBook[o], value: o };
              })}
              value={optionChecked}
              onChange={handleNavigation}
            />
          </div>
        ) : (
          <>
            (
            <div
              ref={segmentedRef}
              className={`${styles.mobileSegmented} ${isFixed ? styles.segmentedFixed : styles.segmented}`}
            >
              {isFixed && (
                <div
                  className={styles.menu}
                  onClick={() => setOpenMobileOptions(!openMobileOptions)}
                >
                  {openMobileOptions ? (
                    <MenuFoldOutlined />
                  ) : (
                    <MenuUnfoldOutlined />
                  )}
                  Opciones
                </div>
              )}
              {!isFixed || (isFixed && openMobileOptions) ? (
                <Segmented
                  vertical
                  options={navOptions.map((o) => {
                    return { label: navBook[o], value: o };
                  })}
                  value={optionChecked}
                  onChange={handleNavigation}
                  className={styles.antdSegmented}
                />
              ) : null}
            </div>
            )
          </>
        )}

        <Divider className={styles.divider} />

        {/* COMO PARTICIPAR */}
        <span className={styles.titleRule} id='howPlay'>
          Cómo participar
        </span>
        {howPlay.map((item, index) => (
          <div key={index} className={styles.containerItemList}>
            <ItemList
              className={styles.numberList}
              type='str'
              str={index + 1 + '.'}
            >
              {item.title}
            </ItemList>
            {item.descriptions ? (
              item.descriptions.map((description, index) => (
                <ItemList className={styles.textList} type='point' key={index}>
                  {description}
                </ItemList>
              ))
            ) : (
              <ItemList className={styles.textList}>
                {item.description}
              </ItemList>
            )}
          </div>
        ))}

        <Divider className={styles.divider} />

        {/* REGLAS GENERALES */}
        <span className={styles.titleRule} id='generalRules'>
          Reglas generales
        </span>
        {generalRules.map((item, index) => (
          <ItemList className={styles.textList} type='point' key={index}>
            {item}
          </ItemList>
        ))}

        <Divider className={styles.divider} />

        {/* QUE ES FIXTURE GOLDEN */}
        <span className={styles.titleRule} id='goldenFixtures'>
          ¿Qué es el Fixture Golden?
        </span>
        {goldenFixtures.map((item, index) => (
          <div key={index} className={styles.containerItemList}>
            <ItemList className={styles.numberList}>{item.title}</ItemList>
            {item.descriptions &&
              item.descriptions.map((description, index) => (
                <ItemList className={styles.textList} type='point' key={index}>
                  {description}
                </ItemList>
              ))}
          </div>
        ))}

        <Divider className={styles.divider} />

        {/* VER RESULTADOS Y POSICIONES DE CADA FECHA*/}
        <span className={styles.titleRule} id='reviewResults'>
          Revisión de posiciones y predicciones
        </span>
        {reviewResults.map((item, index) => (
          <div key={index} className={styles.containerItemList}>
            <ItemList
              className={styles.numberList}
              type='str'
              str={index + 1 + '.'}
            >
              {item.title}
            </ItemList>
            {item.descriptions &&
              item.descriptions.map((description, index) => (
                <ItemList className={styles.textList} type='point' key={index}>
                  {description}
                </ItemList>
              ))}
          </div>
        ))}
        <span className={styles.noteText}>
          <strong>Nota: </strong> Las predicciones de los partidos que aún no
          han comenzado estarán ocultas. Una vez que inicie un partido, sus
          predicciones estarán visibles para todos los usuarios.
        </span>

        <Divider className={styles.divider} />

        {/* CAMBIAR DE TORNEO/FECHA */}
        <span className={styles.titleRule} id='changeTournament'>
          Cómo cambiar de Torneos o Fixtures
        </span>
        {changeTournament.map((item, index) => (
          <div key={index} className={styles.containerItemList}>
            <ItemList
              className={styles.numberList}
              type='str'
              str={index + 1 + '.'}
            >
              {item.title}
            </ItemList>
            {item.descriptions &&
              item.descriptions.map((description, index) => (
                <ItemList className={styles.textList} type='point' key={index}>
                  {description}
                </ItemList>
              ))}
          </div>
        ))}
        <span className={styles.noteText}>
          <strong>Nota: </strong>
          Esta funcionalidad te permite moverte fácilmente entre torneos y
          fixtures para analizar resultados, ajustar tus predicciones y explorar
          más detalles. ¡Aprovecha al máximo la flexibilidad de Fixtuwin!
        </span>

        <Divider className={styles.divider} />

        {/* CARGAR CREDITOS */}
        <span className={styles.titleRule} id='depositCredits'>
          Cargar créditos
        </span>
        {depositCredits.map((item, index) => (
          <div key={index} className={styles.containerItemList}>
            <ItemList
              className={styles.numberList}
              type='str'
              str={index + 1 + '.'}
            >
              {item.title}
            </ItemList>
            {item.descriptions &&
              item.descriptions.map((description, index) => (
                <ItemList className={styles.textList} type='point' key={index}>
                  {description}
                </ItemList>
              ))}
          </div>
        ))}

        <Divider className={styles.divider} />

        {/* RETIRAR CREDITOS */}
        <span className={styles.titleRule} id='withdrawCredits'>
          Retirar créditos
        </span>
        {withdrawCredits.map((item, index) => (
          <div key={index} className={styles.containerItemList}>
            <ItemList
              className={styles.numberList}
              type='str'
              str={index + 1 + '.'}
            >
              {item.title}
            </ItemList>
            {item.descriptions &&
              item.descriptions.map((description, index) => (
                <ItemList className={styles.textList} type='point' key={index}>
                  {description}
                </ItemList>
              ))}
          </div>
        ))}
        <span className={styles.noteText}>
          <strong>Nota: </strong>
          Asegúrate de que los datos de tu cuenta bancaria sean correctos para
          evitar demoras en la acreditación. Si tienes algún inconveniente con
          la carga o el retiro de créditos, puedes contactar a nuestro equipo de
          soporte.
        </span>
      </div>
    </Column>
  );
};

export default Instructions;
