import React from 'react';
import styles from './transactionTable.module.css';
import HistoryDetailModal from './historyDetailModal/HistoryDetailModal';
import {
  bookStatusTransaction,
  bookTypeTransaction,
  TransactionAttributes,
} from '../../../../../../../types/transaction.types';
import {
  formatDate,
  formatPrice,
  generateId,
} from '../../../../../../../utils';

type TransactionTableProps = {
  transactions: TransactionAttributes[];
};
const TransactionTable: React.FC<TransactionTableProps> = ({
  transactions,
}) => {
  const [detailModal, setDetailModal] = React.useState<{
    open: boolean;
    transactionId?: number;
  }>({ open: false });

  const handleRowClick = (transactionId?: number) => {
    setDetailModal({ open: true, transactionId });
  };
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Transacción ID</th>
            <th>Fecha</th>
            <th>Tipo</th>
            <th>Creditos</th>
            <th>Costo</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => {
            const { date, time } = formatDate(transaction.createdAt);

            return (
              <tr
                key={transaction.id}
                className={styles.tableRow}
                onClick={() => handleRowClick(transaction.id)}
              >
                <td>{generateId('TC', transaction.id)}</td>
                <td>{`${date} ${time}`}</td>
                <td>{bookTypeTransaction[transaction.type]}</td>
                <td>{transaction.creditAmount}</td>
                <td>{formatPrice(transaction.price)}</td>
                {transaction.status && (
                  <td className={styles[transaction?.status]}>
                    {bookStatusTransaction[transaction?.status]}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {detailModal.open && (
        <HistoryDetailModal
          open={detailModal.open}
          onClose={() => setDetailModal({ open: false })}
        />
      )}
    </div>
  );
};

export default TransactionTable;
