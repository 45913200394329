import React, { useState } from 'react';
import { useGetAllFixtureTickets } from '../../../../../../../serverStore/queries';
import ShowMoreTicketsModal from '../../../../../../../modals/showMoreTicketsModal/ShowMoreTicketsModal';
import { capitalizeFirstLetter } from '../../../../../../../../utils';

interface IShowMoreFixturesTicketProps {
  fixtureId: number;
  openTableModal: boolean;
  onClose?: () => void;
  isStarted: boolean;
  fixtureName: string;
}

const ShowMoreFixturesTicket: React.FC<IShowMoreFixturesTicketProps> = ({
  fixtureId,
  openTableModal,
  onClose,
  isStarted,
  fixtureName,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useGetAllFixtureTickets({
    groupped: true,
    page: String(currentPage),
    resultsPerPage: '15',
    fixtureId: fixtureId,
    enabledRequest: !!fixtureId,
  });

  return (
    <ShowMoreTicketsModal
      currentPage={currentPage}
      grouppedTickets={data?.results}
      onChangePagination={(newPage) => setCurrentPage(newPage)}
      totalTickets={data?.count}
      isStarted={isStarted}
      isLoading={isLoading}
      onClose={onClose}
      open={openTableModal}
      ticketName='fixtureTicket'
      title={
        capitalizeFirstLetter(`Tabla de Posiciones - ${fixtureName}`) || ''
      }
    />
  );
};

export default ShowMoreFixturesTicket;
