import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';
import {
  PredictionAttributes,
  ResponsePredictions,
} from '../../../../types/prediction.types';

import useClientStore from '../../../clientStore/useClientStore';
import { SimplePrediction } from '../../../screens/home/components/fixtureSection/FixtureSection';
import encryptData from '../../../../utils/hooks/encryptData';
import { decryptData } from '../../../../utils';

export type DataSavePredictions = {
  predictions: SimplePrediction[];
  // fixtureTicketType: FixtureTicketType;
  roundId: number;
  //tournamentTicketType: TournamentTicketType;
  fixtureId: number;
  userId: string;
  savePredictionsSecret: string;
};

export type ParamsSavePredictions = {
  data: DataSavePredictions;
  x: string;
};

type IPostSavePredictions = ({
  data,
  x,
}: ParamsSavePredictions) => Promise<ResponsePredictions>;
const postSavePredictions: IPostSavePredictions = async ({ data, x }) => {
  const encryptedPredictions = await encryptData(data, x);
  const response = await axiosFetch('/prediction', {
    method: 'POST',
    data: { encryptedPredictions },
  });
  const decrypPredictionstData = await decryptData(response);
  return decrypPredictionstData;
};

const useSavePrediction: ({
  fixtureId,
  tournamentId,
}: {
  fixtureId?: number;
  tournamentId?: number;
}) => UseMutationResult<
  PredictionAttributes,
  any,
  ParamsSavePredictions,
  unknown
> = ({ fixtureId, tournamentId }) => {
  const serverStore = useQueryClient();
  const { dispatch } = useClientStore();

  return useMutation({
    mutationFn: postSavePredictions,
    onSuccess: () => {
      localStorage.removeItem('savePredictions');

      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          message: 'Predicciones guardadas',
          description: 'Las predicciones fueron guardadas',
          status: 'success',
        },
      });

      serverStore.invalidateQueries({
        queryKey: ['tickets-fixture', fixtureId],
      });
      serverStore.invalidateQueries({
        queryKey: ['tickets-tournament', tournamentId],
      });

      /*     serverStore.invalidateQueries({
        queryKey: [`predictions-${p.roundId}-${loggedUser?.email}`],
      }); */
    },
    onError: () => {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          message: 'Error',
          description:
            'Ocurrio un error al guardar las predicciones. Por favor intenta de nuevo mas tarde',
          status: 'error',
        },
      });
    },
  });
};

export default useSavePrediction;
