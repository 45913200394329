import React, { useState } from 'react';

import styles from './fixtureName.module.css';
import useClientStore from '../../../../clientStore/useClientStore';
import { useGetFixtureNames } from '../../../../serverStore/queries';
import CustomTooltip from '../../../customTooltip/CustomTooltip';
import { capitalizeFirstLetter } from '../../../../../utils';
import FixtureListModal from '../../../../modals/home/FixtureListModal';

interface IFixtureNameProps {
  //fixture?: FixtureAttributes;
  fixtureName?: string;
  refFixtureChangeName: React.MutableRefObject<null>;
  isGolden?: boolean;
  onlyView?: boolean;
}

const FixtureName: React.FC<IFixtureNameProps> = ({
  fixtureName,
  refFixtureChangeName,
  isGolden,
  onlyView,
}) => {
  const [openFixturesListModal, setOpenFixturesListModal] = useState(false);

  const {
    dispatch,
    fixtureSelected: { tournamentId, fixtureId },
  } = useClientStore();
  const { data: fixtureNames } = useGetFixtureNames(tournamentId);

  /* Fixture names esta ordenado de tal manera que los fixtures antiguos estan primeros en el array */
  const fixturePagination = fixtureNames?.reduce(
    (acc, f, i) => {
      if (f.id === fixtureId) {
        /* Si tiene otro fixture después que el actual en el array (índice más alto), entonces puede ir al siguiente */
        if (fixtureNames[i + 1]) {
          acc.isNext = true;
          acc.nextId = fixtureNames[i + 1].id;
        }
        /* Si tiene otro fixture antes que el actual en el array (índice más bajo), entonces puede ir al anterior */
        if (fixtureNames[i - 1]) {
          acc.isPrev = true;
          acc.prevId = fixtureNames[i - 1].id;
        }
      }
      return acc;
    },
    { isPrev: false, isNext: false, prevId: 0, nextId: 0 },
  );

  const handleOpenFixtureList = () => {
    if (onlyView) return;
    setOpenFixturesListModal(true);
  };

  return (
    <>
      <div
        className={`${styles.fixtureContainer} ${isGolden && styles.golden} `}
      >
        <div className={styles.iconFixture}>
          {fixturePagination?.isPrev && !onlyView ? (
            <i
              className='fas fa-angle-left'
              onClick={() =>
                dispatch({
                  type: 'SET_FIXTURE_ID',
                  payload: fixturePagination.prevId,
                })
              }
            ></i>
          ) : null}
        </div>
        <CustomTooltip
          title='Presiona para cambiar de fixture'
          disabled={onlyView}
        >
          <p
            ref={refFixtureChangeName}
            className={`${styles.fixturesText} ${onlyView && styles.fixturesTextOnlyView}`}
            onClick={handleOpenFixtureList}
          >
            {capitalizeFirstLetter(fixtureName)}
          </p>
        </CustomTooltip>
        <div className={styles.iconFixture}>
          {fixturePagination?.isNext && !onlyView ? (
            <i
              className='fas fa-angle-right'
              onClick={() =>
                dispatch({
                  type: 'SET_FIXTURE_ID',
                  payload: fixturePagination.nextId,
                })
              }
            ></i>
          ) : null}
        </div>
      </div>

      {openFixturesListModal ? (
        <FixtureListModal
          open={openFixturesListModal}
          onClose={() => setOpenFixturesListModal(false)}
        />
      ) : null}
    </>
  );
};

export default FixtureName;
