import React from 'react';
import styles from './footer.module.css';
import { TikTokOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import FooterImage from '../../../../assets/footer.jpg';
import LogoPelota from '../../../../assets/logoPelota.png';
import Flex from '../Flex';
import Column from '../Column';
import { Divider } from '../../../../components/commons';

const ItemIcon: React.FC<{ icon: JSX.Element; name: string; url: string }> = ({
  icon,
  name,
  url,
}) => {
  return (
    <Tooltip title={name}>
      <div className={styles.icon} onClick={() => window.open(url, '_blank')}>
        {icon}
      </div>
    </Tooltip>
  );
};

const Footer: React.FC = () => {
  return (
    <div className={styles.containerFooter}>
      <div className={styles.containerFooterImg}>
        <div
          className={`${styles.backgroundFooterImg} `}
          style={{
            backgroundImage: `url(${FooterImage})`,
          }}
        />
        <Column className={styles.containerColumnsFooter}>
          <Column alignItems='center' className={styles.logoContainer}>
            <Flex justifyContent='center' alignItems='center' gap={8}>
              <img src={LogoPelota} alt='Logo' className={styles.logo} />
              <span className={styles.title}>FIXTUWIN</span>
            </Flex>
            <span> Donde las predicciones cobran vida.</span>
          </Column>
          <div className={styles.containerText}>
            <span className={styles.text}>
              El juego está prohibido para los menores y puede generar adicción
              patológica y trastornos del juego
            </span>
            <Flex className={styles.containerLinks}>
              <span className={styles.link}>Politica de privacidad</span>
              <span className={styles.link}>Términos y condiciones</span>
              <span className={styles.link}>Reglamento</span>
              <span className={styles.link}>Preguntas frecuentes</span>
            </Flex>
          </div>
          <div>
            <Divider className={styles.divider} />
            <Flex justifyContent='center' className={styles.containerIcons}>
              <ItemIcon
                name='Facebook'
                icon={<i className='fab fa-facebook' />}
                url='https://www.facebook.com/profile.php?id=61556748842145'
              />
              <ItemIcon
                name='Instagram'
                icon={<i className='fab fa-instagram'></i>}
                url='https://www.instagram.com/fixtuwin'
              />
              <ItemIcon
                name='X (twitter)'
                icon={<i className='fab fa-twitter'></i>}
                url='https://twitter.com/Fixtuwin'
              />
              <ItemIcon
                name='Tik Tok'
                icon={<TikTokOutlined />}
                url='https://www.tiktok.com/@fixtuwinok?lang=es'
              />
            </Flex>

            <span className={styles.copyRight}>&copy; 2024 Fixtuwin</span>
            <Divider className={styles.divider} />
          </div>
        </Column>
      </div>
    </div>
  );
};

export default Footer;
