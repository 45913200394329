import React from 'react';
import { DarkModal } from '../../../../../../commons';
import { WithdrawlForm } from '../WithdrawlSection';
import { formatPrice } from '../../../../../../../utils';
import { Column } from '../../../../../../../utils/layout';
import styles from './withdrawlModal.module.css';
type WithdrawlModalProps = {
  open?: boolean;
  onClose?: () => void;
  values: WithdrawlForm;
  onClickConfirm?: () => void;
};

const WithdrawlModal: React.FC<WithdrawlModalProps> = ({
  onClose,
  open,
  values,
  onClickConfirm,
}) => {
  return (
    <DarkModal
      open={open}
      onClose={onClose}
      title='Confirmar Retiro'
      footer
      onConfirmText='Confirmar Retiro'
      onConfirm={onClickConfirm}
    >
      <Column className={styles.container}>
        <span>
          Vas a retirar: <strong>{values.creditAmount || 0} creditos</strong>
        </span>
        <span>
          Recibirás: <strong>{formatPrice(values.creditAmount)}</strong> en tu
          cuenta bancaria
        </span>
        <span>
          Nombre del banco: <strong>{values.bankName}</strong>
        </span>
        <span>
          CBU/CVU de destino: <strong>{values.cbu}</strong>
        </span>
        <span>
          DNI: <strong>{values.dni}</strong>
        </span>

        <span className={styles.importantText}>
          <strong>Importante:</strong> Por favor, verifica cuidadosamente la
          información antes de continuar.Una vez confirmado el retiro y
          transferido el dinero a la cuenta, no podrás cancelar ni modificar
          ningún dato.
        </span>
      </Column>
    </DarkModal>
  );
};

export default WithdrawlModal;
