import { TypeTransaction } from '../../../../types/transaction.types';
import useClientStore from '../../../clientStore/useClientStore';

type CreditUpdateData = {
  email: string;
  creditAmount: number;
  credits: number;
  type: TypeTransaction;
};

const useCreditUpdateHandler = () => {
  const { loggedUser, dispatch } = useClientStore();

  const handleMatchUpdate = (updateData: CreditUpdateData) => {
    const { creditAmount, credits, email, type } = updateData;
    if (!loggedUser || loggedUser.email !== email) return;

    dispatch({ type: 'SET_CREDITS', payload: credits });

    if (type === 'withdrawal') {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          status: 'success',
          message: `¡Retiro exitoso! Tus ${creditAmount} crditos han sido retirados.`,
        },
      });
      return;
    }
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        status: 'success',
        message: `¡Carga exitosa! Tus ${creditAmount} créditos han sido agregados.`,
      },
    });
  };

  return handleMatchUpdate;
};

export default useCreditUpdateHandler;
