import { Input } from 'antd';

const primary = '#f9ba15';
const white = '#ffff';
const dark = '#0a1014';
const midGrey = '#9b9b9b';

// Configuración del tema
const theme = {
  token: {
    // Seed Token
    colorPrimary: '#f9ba15',
    borderRadius: 16,
    colorSuccess: '#52c41a',
    colorWarning: '#ffc400',
    colorError: '#f5222d',

    colorLinkHover: '#f9ba15',
    colorLink: '#ffff',
    colorPrimaryHover: '#f3ae00',
    colorTextLightSolid: '#1b1b1b',
    // Alias Token
    colorBgContainer: 'white',
  },
  components: {
    Upload: {
      /* here is your component tokens */
    },
    Select: {
      /* here is your component tokens */
      optionActiveBg: '#585858',
      optionSelectedBg: '#f9ba15',
      selectorBg: '#1b1b1b',
      optionSelectedColor: '#ffff',
      optionSelectedFontWeight: 800,
    },
    Input: {
      colorBgContainer: '#1b1b1b',
      colorIcon: '#f9ba15',
      colorIconHover: '#f6a623',
      colorFillSecondary: 'red',
      colorPrimaryActive: '#f9ba15',
      colorPrimaryHover: '#f6a623',
      colorText: '#ffff',
      colorTextDescription: '#9b9b9b',
      colorTextDisabled: '#9b9b9b',
      colorTextPlaceholder: '#9b9b9b',
      colorTextQuaternary: '#9b9b9b',
      colorTextTertiary: '#9b9b9b',
    },
    Form: {
      labelColor: 'white',
    },
    Notification: {
      colorBgElevated:
        'linear-gradient(145deg, #000000, #0a1014, #0a1014, #000000)',
      colorBgTextActive: 'red',
      colorBgTextHover: 'red',
      colorIcon: '#f9ba15',
      colorIconHover: '#f6a623',
      colorText: '#9b9b9b',
      colorTextDescription: '#9b9b9b',
      colorTextHeading: '#ffff',
      boxShadow: 'inset 0 0 5px #fff',
      colorInfo: '#f9ba15',
    },

    Dropdown: {
      colorBgElevated: '#0a1014',
      colorPrimary: '#f9ba15',
      colorPrimaryBorder: '#f9ba15',
      colorSplit: '#9b9b9b',
      colorText: '#ffff',
      colorTextDescription: '#9b9b9b',
      colorTextDisabled: '#9b9b9b',
      boxShadowSecondary: 'inset 0 0 5px #fff',
      controlItemBgActive: '#f9ba15',
      controlItemBgActiveHover: '#f6a623',
      controlItemBgHover: '#767676',
    },
    Segmented: {
      itemActiveBg: primary,
      itemColor: white,
      itemSelectedBg: primary,
      itemSelectedColor: dark,
      trackBg: 'linear-gradient(145deg, #1f1f1f, #2c2c2c, #2c2c2c, #1f1f1f);',
      itemHoverBg: midGrey,
      colorBgElevated: 'red',
    },
    Tour: {
      colorBgElevated: '#123a54',
      colorPrimaryBorder: 'inset 0 0 5px #fff',
      colorText: '#ffff',
      colorIcon: '#f9ba15',
    },
  },
};

// Exporta el tema
export default theme;
