import React, { useEffect, useState } from 'react';
import { useGetAllTournamentTickets } from '../../../../../../serverStore/queries';
import { validateStartDate } from '../../../../../../../utils/hooks/handleDate';
import { TournamentWithFixtureName } from '../../../../../../../types/tournament.types';
import ShowMoreTournamentTickets from './components/ShowMoreTournamentTickets';
import useClientStore from '../../../../../../clientStore/useClientStore';
import PositionsTable from '../homePositionsTable/HomePositionsTable';

interface ITournamentResultProps {
  tournament: TournamentWithFixtureName;
}

const TournamentResult: React.FC<ITournamentResultProps> = ({ tournament }) => {
  const {
    fixtureSelected: { tournamentId },
  } = useClientStore();
  const selectedTournamentId = tournament?.id || tournamentId;

  const { data, refetch: refetchTournamentTickets } =
    useGetAllTournamentTickets({
      groupped: true,
      page: '1',
      resultsPerPage: '10',
      tournamentId: selectedTournamentId ? selectedTournamentId : undefined,
      enabledRequest: !!selectedTournamentId,
    });
  const [openTableModal, setOpenTableModal] = useState(false);

  const isStarted = !validateStartDate(tournament?.startDate);

  const handleCloseTableModal = () => {
    setOpenTableModal(false);
  };

  const handleshowMoreTickets = () => {
    setOpenTableModal(true);
  };

  useEffect(() => {
    refetchTournamentTickets();
  }, [selectedTournamentId]);

  if (!data?.results) return null;

  return (
    <>
      <PositionsTable
        isStarted={isStarted}
        grouppedTickets={data.results}
        totalTickets={data.count}
        onClickShowMoreTickets={handleshowMoreTickets}
        ticketName={'tournamentTicket'}
        currentPage={1}
      />

      {/* TABLA PARA MOSTRAR MAS TICKETS */}
      {openTableModal && tournament?.id ? (
        <ShowMoreTournamentTickets
          tournamentId={tournament?.id}
          openTableModal={openTableModal}
          onClose={handleCloseTableModal}
          isStarted={isStarted}
          tournamentName={tournament?.name}
        />
      ) : null}
    </>
  );
};

export default TournamentResult;
