import { Actions } from './actions';
import { ClientStore } from './clientStore.types';

const reducer = (draft: ClientStore, action: Actions): void => {
  switch (action.type) {
    case 'LOGOUT':
      void localStorage.removeItem('auth');
      draft.authentication = null;
      draft.loggedUser = undefined;
      break;

    case 'AUTH': {
      const { csrfToken } = action.payload;
      draft.authentication = action.payload;
      void localStorage.setItem(
        'auth',
        JSON.stringify({
          csrfToken,
        }),
      );
      break;
    }

    case 'SET_USER': {
      draft.loggedUser = action.payload;
      break;
    }

    case 'LOADING': {
      draft.isLoading = action.payload;
      break;
    }

    case 'SET_NOTIFICATION': {
      draft.notification = action.payload;
      break;
    }

    case 'SET_TOURNAMENT_ID': {
      void localStorage.setItem('tournamentSelected', String(action.payload));
      draft.fixtureSelected.tournamentId = action.payload;
      draft.fixtureSelected.fixtureId = undefined;
      draft.fixtureSelected.roundId = undefined;
      break;
    }

    case 'SET_FIXTURE_ID': {
      draft.fixtureSelected.fixtureId = action.payload;
      draft.fixtureSelected.roundId = undefined;
      break;
    }

    case 'SET_ROUND_ID': {
      draft.fixtureSelected.roundId = action.payload;
      break;
    }

    case 'SET_SCROLL': {
      draft.isScrolling = action.payload;
      break;
    }

    case 'SET_CREDITS': {
      if (draft.loggedUser) {
        draft.loggedUser.credits = action.payload;
      }
      break;
    }

    default:
      throw new Error('Invalid action type');
  }
};

export default reducer;
