import React from 'react';
import styles from './mpButton.module.css';
import mpLogo from '../../../assets/mpLogo.png';

type MpButtonProps = {
  url: string;
  onMpButtonClick?: () => void;
};

const MpButton: React.FC<MpButtonProps> = ({ url, onMpButtonClick }) => {
  const handleClick = () => {
    onMpButtonClick?.();
    window.open(url, '_blank'); // Abre la URL en una nueva pestaña
  };
  return (
    <div className={styles.container}>
      <button onClick={handleClick} className={styles.mpButton}>
        <img src={mpLogo} alt='Mercado Pago Logo' className={styles.logo} />
        Pagar con Mercado Pago
      </button>
      <span className={styles.text}>Pagá de forma segura</span>
    </div>
  );
};

export default MpButton;
