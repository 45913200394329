import React, { useMemo } from 'react';
import { DarkModal, Divider, SpinerScreen } from '../../commons';
import { Column, Flex } from '../../../utils/layout';
import { formatPrice } from '../../../utils';
import styles from './checkoutModal.module.css';
import { useGetMpPreference } from '../../serverStore/queries';
import { CreditsPacksId } from '../../../types/credit.types';
import MpButton from '../../commons/mpButton/MpButton';
import { Spin } from 'antd';
import mpLogo from '../../../assets/mpLogo.png';
import visaLogo from '../../../assets/creditCards/visa.png';
import mastercardLogo from '../../../assets/creditCards/mastercard.png';
import americanLogo from '../../../assets/creditCards/american.png';
type CheckoutModalProps = {
  open?: boolean;
  onClose?: () => void;
  creditAmount: number;
  price: number;
  packId?: CreditsPacksId;
  onMpButtonClick?: () => void;
};
const CheckoutModal: React.FC<CheckoutModalProps> = ({
  onClose,
  open,
  creditAmount,
  price,
  packId,
  onMpButtonClick,
}) => {
  const params = useMemo(
    () => ({ creditAmount, packId }),
    [creditAmount, packId],
  );
  const { data, isLoading } = useGetMpPreference(params);

  if (!open) {
    return null;
  }

  return (
    <DarkModal
      open={open}
      onClose={onClose}
      title='Finaliza la compra'
      className={styles.modal}
    >
      <Column className={styles.content}>
        <Flex className={styles.header}>
          <Flex className={styles.coins}>
            <i className='fas fa-coins'></i>
            <span>
              {`${packId ? 'Pack' : 'Cargar'} ${creditAmount} Creditos`}{' '}
            </span>
          </Flex>
          <span>{formatPrice(price)}</span>
        </Flex>

        {isLoading && <Spin size='large' className={styles.spiner} />}
        {!isLoading && (
          <span className={styles.infoText}>
            Serás redirigido a Mercado Pago para completar tu pago. Una vez
            confirmado, verás los créditos reflejados en tu cuenta.
          </span>
        )}

        {!isLoading && data && data.url && (
          <MpButton url={data?.url} onMpButtonClick={onMpButtonClick} />
        )}

        <span className={styles.aclaration}>
          Puedes pagar con tarjetas de crédito, débito o tu cuenta de Mercado
          Pago. Si no tienes cuenta, puedes pagar directamente con tus tarjetas
          sin registrarte.
        </span>

        <Flex className={styles.paymentMethods}>
          <img src={visaLogo} alt='Visa' className={styles.visa} />
          <img
            src={mastercardLogo}
            alt='Mastercard'
            className={styles.mastercard}
          />
          <img
            src={americanLogo}
            alt='American Express'
            className={styles.american}
          />

          <img src={mpLogo} alt='Mercado Pago' className={styles.logo} />
        </Flex>

        <Divider className={styles.divider} />
        <span className={styles.description}>
          Todos los precios incluyen IVA. Si continúas, confirmas que eres mayor
          de 18 años y aceptas los{' '}
          <span className={styles.terms}>Terminos y Condiciones</span>.
        </span>
      </Column>
    </DarkModal>
  );
};

export default CheckoutModal;
