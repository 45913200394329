import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';
import useClientStore from '../../../clientStore/useClientStore';

type BodyProps = {
  fixtureId: number;
  fixtureTicketType: 'golden';
};
type IPostChangeGoldenFixture = (body: BodyProps) => Promise<string>;

const postChangeGoldenFixture: IPostChangeGoldenFixture = async (body) => {
  const response = await axiosFetch('/ticket/change-golden', {
    method: 'POST',
    data: body,
  });
  return response;
};

const useChangeGoldenFixture: (
  fixtureId?: number,
) => UseMutationResult<string, any, BodyProps, unknown> = (
  fixtureId?: number,
) => {
  const serverStore = useQueryClient();
  const { dispatch, loggedUser } = useClientStore();

  return useMutation({
    mutationFn: postChangeGoldenFixture,
    onSuccess: () => {
      serverStore.invalidateQueries({
        queryKey: ['ticket-fixture-by-user', loggedUser?.email, fixtureId],
      });
      serverStore.invalidateQueries({
        queryKey: ['tickets-fixture', fixtureId],
      });

      console.log('CONSOLE -useChangeGoldenFixture- ', { fixtureId });

      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          status: 'success',
          message:
            '   Tu Fixture Golden está activo. Estás participando por los créditoS exclusivos de esta fecha. ¡Prepárate para destacar y ganar!',
        },
      });
    },
  });
};

export default useChangeGoldenFixture;
