import React from 'react';
import { GenericTickets } from '../../../../../types/ticket.types';
import { TicketName } from '../../../../modals/showPredictionsModal/ShowPredictionsModal';
import useClientStore from '../../../../clientStore/useClientStore';
import { useScreenResize } from '../../../../../utils';
import styles from './rowTable.module.css';
import { CustomTooltip } from '../../../../commons';

type RowTableProps = {
  ticket: GenericTickets;
  isStarted?: boolean;
  position: number;
  isFirstPosition?: boolean;
  ticketName: TicketName;
  onRowClick?: (email: string, ticket: GenericTickets) => void;
};

const RowTable: React.FC<RowTableProps> = ({
  position,
  ticket,
  isStarted,
  isFirstPosition,

  ticketName,
  onRowClick,
}) => {
  const { loggedUser } = useClientStore();
  const { isDesktop } = useScreenResize();

  const enabledShow =
    loggedUser &&
    (loggedUser?.userType === 'administrator' ||
      isStarted ||
      loggedUser?.email === ticket.user?.email);

  const titleTooltip = !loggedUser
    ? 'Inicia sesion para ver'
    : 'La fecha todavia no comenzó';

  const handleRowClick = () => {
    if (enabledShow && ticket.user?.email) {
      onRowClick?.(ticket.user.email, ticket);
    }
  };

  return (
    <tr
      key={ticket.id}
      className={`${styles.tableRow} ${isFirstPosition && styles.first}`}
      onClick={handleRowClick}
    >
      <td>
        {isFirstPosition ? <i className='fa-solid fa-trophy' /> : position}
      </td>
      <td className={styles.leftText}>
        {`${ticket.user?.name} ${ticket.user?.lastname}`}
      </td>
      <td>
        {ticket.ticketType === 'golden' && (
          <CustomTooltip
            className={styles.ticketIcon}
            title={
              'Este fixture es golden y compite por los créditos exclusivos de la fecha'
            }
          >
            <i className='fa-solid fa-ticket' />
          </CustomTooltip>
        )}
      </td>
      <td>{ticket?.resultPoints || 0}</td>
      {ticketName === 'fixtureTicket' && <td>{ticket?.tournamentRank || 0}</td>}
      <td>
        {enabledShow ? (
          <i className='far fa-eye' />
        ) : (
          <CustomTooltip
            title={titleTooltip}
            disabled={enabledShow}
            placement={isDesktop ? 'top' : 'left'}
          >
            <i className='far fa-eye-slash' />
          </CustomTooltip>
        )}
      </td>
    </tr>
  );
};

export default RowTable;
