import React, { useState } from 'react';
import Flex from '../../../Flex';
import styles from './authHeader.module.css';
import useWriting from '../../../../../../copywriting/useWriting';
import AuthModal, {
  AuthType,
} from '../../../../../../components/modals/auth/AuthModal';
import { Dropdown, MenuProps } from 'antd';
import { UserOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useClientStore from '../../../../../../components/clientStore/useClientStore';
import Column from '../../../Column';
import { Button } from '../../../../../../components/commons';
import LogoutModal from '../../../../../../components/modals/auth/components/logout/LogoutModal';

const AuthHeader: React.FC = () => {
  const writing = useWriting();
  const navigate = useNavigate();
  const { loggedUser } = useClientStore();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [authModal, setAuthModal] = useState<{
    open?: boolean;
    authType?: AuthType;
  }>({});

  const items: MenuProps['items'] = [
    {
      key: '4',
      label: (
        <Column
          className={styles.containerCredits}
          onClick={() => navigate('/user/credits')}
        >
          <i className='fas fa-coins' />
          <div>{loggedUser?.credits || 0} Creditos</div>
        </Column>
      ),
      disabled: false,
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div onClick={() => navigate('/user/profile')}>
          {writing.authHeader.myProfile}
        </div>
      ),
    },

    {
      key: '5',
      label: <div onClick={() => navigate('/user/credits')}>Mis creditos</div>,
    },
    {
      key: '1',
      label: (
        <div onClick={() => setIsConfirmModalOpen(true)}>
          {writing.authHeader.logout}
        </div>
      ),
    },
  ];

  const handleOpen = (authType?: AuthType) => {
    setAuthModal({ open: true, authType });
  };

  const handleClose = () => {
    setAuthModal({ open: false });
  };

  return (
    <>
      {!loggedUser ? (
        <>
          <Flex style={{ gap: 16, alignItems: 'center' }}>
            <span
              className={styles.loginText}
              onClick={() => handleOpen('login')}
            >
              {writing.user.login.title}
            </span>

            <Button
              className={styles.registerButton}
              onClick={() => handleOpen('register')}
            >
              {writing.user.register.title}
            </Button>
            {/*  </span> */}
          </Flex>
          {authModal.authType && authModal.open ? (
            <AuthModal
              open={authModal.open}
              authType={authModal.authType}
              handleClose={handleClose}
            />
          ) : null}
        </>
      ) : (
        <>
          <Dropdown menu={{ items }}>
            <div
              className={styles.dropdown}
              onClick={(e) => e.preventDefault()}
            >
              <UserOutlined />
              {`${loggedUser.name} ${loggedUser.lastname}`}
              <DownOutlined className={styles.down} />
              <UpOutlined className={styles.up} />
            </div>
          </Dropdown>

          {isConfirmModalOpen && (
            <LogoutModal
              open={isConfirmModalOpen}
              onClose={() => setIsConfirmModalOpen(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default AuthHeader;
