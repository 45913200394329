import { useQuery } from '@tanstack/react-query';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';
import useClientStore from '../../../clientStore/useClientStore';
import { TransactionAttributes } from '../../../../types/transaction.types';

const getUserTransactions = async (
  email?: string,
): Promise<TransactionAttributes[]> => {
  const response: TransactionAttributes[] = await axiosFetch(
    `/transaction/user?email=${email}`,
  );
  return response;
};

export default function useGetUserTransactions(email?: string) {
  const { loggedUser } = useClientStore();
  return useQuery({
    queryKey: ['transactions', email],
    queryFn: () => getUserTransactions(email),
    enabled: !!email && !!loggedUser,
  });
}
