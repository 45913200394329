import React, { useState } from 'react';
import styles from './positionsTable.module.css';

import RowTable from './components/rowTable/RowTable';
import HeaderTable from './components/headerTable/HeaderTable';
import { GenericTickets, GroupedTickets } from '../../../types/ticket.types';
import ShowPredictionsModal, {
  TicketName,
} from '../../modals/showPredictionsModal/ShowPredictionsModal';
import useClientStore from '../../clientStore/useClientStore';

type PositionsTableProps = {
  grouppedTickets: GroupedTickets;
  isStarted: boolean;
  ticketName: TicketName;
  currentPage: number;
};

const PositionsTable: React.FC<PositionsTableProps> = ({
  grouppedTickets,

  isStarted,
  ticketName,
  currentPage,
}) => {
  const { loggedUser } = useClientStore();
  const [showPredictions, setShowPredictions] = useState<{
    open: boolean;
    email: string | null;
    ticket?: GenericTickets;
  }>({
    open: false,
    email: null,
  });
  const resultsPerPage = 15;
  const restInitialIndex = grouppedTickets?.first?.length ? 4 : 1;

  const restPagesInitialIndex =
    restInitialIndex === 1 ? (currentPage - 1) * resultsPerPage : 0;

  const handleRowClick = (email: string, t: GenericTickets) => {
    if (loggedUser) {
      setShowPredictions({
        open: true,
        email,
        ticket: t,
      });
    }
  };

  return (
    <div>
      <table className={styles.table}>
        <HeaderTable ticketName={ticketName} />
        <tbody>
          {grouppedTickets?.first?.map((t: GenericTickets, i: any) => (
            <RowTable
              key={`tick-${1}-${i}`}
              ticket={t}
              position={1}
              isStarted={isStarted}
              isFirstPosition
              ticketName={ticketName}
              onRowClick={handleRowClick}
            />
          ))}
          {grouppedTickets?.second?.map((t: GenericTickets, i: any) => (
            <RowTable
              key={`tick-${1}-${i}`}
              ticket={t}
              position={2}
              isStarted={isStarted}
              ticketName={ticketName}
              onRowClick={handleRowClick}
            />
          ))}
          {grouppedTickets?.third?.map((t: GenericTickets, i: any) => (
            <RowTable
              key={`tick-${1}-${i}`}
              ticket={t}
              position={3}
              isStarted={isStarted}
              ticketName={ticketName}
              onRowClick={handleRowClick}
            />
          ))}

          {grouppedTickets?.rest?.map((t, i) => {
            return (
              <RowTable
                key={`tick-${i + restInitialIndex}`}
                ticket={t}
                position={i + restInitialIndex + restPagesInitialIndex}
                isStarted={isStarted}
                ticketName={ticketName}
                onRowClick={handleRowClick}
              />
            );
          })}
        </tbody>
      </table>

      {showPredictions.open &&
      showPredictions.email &&
      showPredictions.ticket ? (
        <ShowPredictionsModal
          open={showPredictions.open}
          onClose={() => setShowPredictions({ open: false, email: null })}
          ticketName={ticketName}
          ticket={showPredictions.ticket}
          email={showPredictions.email}
        />
      ) : null}
    </div>
  );
};

export default PositionsTable;
