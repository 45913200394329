import React from 'react';
import { Column } from '../../../utils/layout';
import { GoogleLogin } from '@react-oauth/google';
import { useLoginGoogle } from '../../serverStore/mutations';
import useClientStore from '../../clientStore/useClientStore';
import useWriting from '../../../copywriting/useWriting';
import Divider from '../divider/Divider';
import styles from './googleButton.module.css';
import { useScreenResize } from '../../../utils';

type GoogleButton = {
  handleClose?: () => void;
  width?: string;
};
const GoogleButton: React.FC<GoogleButton> = ({ handleClose, width }) => {
  const { mutate } = useLoginGoogle();
  const { dispatch } = useClientStore();
  const writing = useWriting();
  const { isDesktop } = useScreenResize();

  return (
    <Column alignItems='center'>
      <Divider className={styles.divider} />
      <GoogleLogin
        useOneTap
        onSuccess={(credentialResponse) => {
          if (credentialResponse.credential) {
            mutate({ credential: credentialResponse.credential });
            handleClose && handleClose();
            dispatch({
              type: 'SET_NOTIFICATION',
              payload: {
                status: 'success',
                message: writing.user.login.successMessage,
              },
            });
          }
        }}
        onError={() => {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              message: writing.user.login.errorMessage,
              status: 'error',
            },
          });
        }}
        theme='filled_black'
        size='large'
        shape='pill'
        width={isDesktop ? '600px' : ''}
      />
    </Column>
  );
};

export default GoogleButton;
