import React, { useState } from 'react';
import { useGetAllTournamentTickets } from '../../../../../../../serverStore/queries';
import ShowMoreTicketsModal from '../../../../../../../modals/showMoreTicketsModal/ShowMoreTicketsModal';
import { capitalizeFirstLetter } from '../../../../../../../../utils';

interface ShowMoreTournamentTicketsProps {
  tournamentId: number;
  openTableModal: boolean;
  onClose?: () => void;
  isStarted: boolean;
  tournamentName: string;
}

const ShowMoreTournamentTickets: React.FC<ShowMoreTournamentTicketsProps> = ({
  tournamentId,
  openTableModal,
  onClose,
  isStarted,
  tournamentName,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useGetAllTournamentTickets({
    groupped: true,
    page: String(currentPage),
    resultsPerPage: '15',
    tournamentId: tournamentId,
    enabledRequest: !!tournamentId,
  });

  return (
    <ShowMoreTicketsModal
      currentPage={currentPage}
      grouppedTickets={data?.results}
      onChangePagination={(newPage) => setCurrentPage(newPage)}
      totalTickets={data?.count}
      isStarted={isStarted}
      isLoading={isLoading}
      onClose={onClose}
      open={openTableModal}
      ticketName='tournamentTicket'
      title={
        capitalizeFirstLetter(`Tabla de Posiciones - ${tournamentName}`) || ''
      }
    />
  );
};

export default ShowMoreTournamentTickets;
