import { IWriting } from './writing.type';

export const writingES: IWriting = {
  goBackText: 'Regresar',
  menuTop: {
    home: 'Inicio',
    instructions: 'Instrucciones',
    support: 'Contacto',
  },
  authHeader: {
    myTickets: 'Mis Fixtures',
    myProfile: 'Mi perfil',
    logout: 'Cerrar sesión',
    confirmModal: {
      title: 'Cerrar sesión',
      description: '¿ Estas seguro de que quieres cerrar tu sesión ?',
      confirmButton: 'Cerrar sesión',
      cancelButton: 'Cancelar',
    },
  },
  user: {
    login: {
      title: 'Iniciar sesión',
      successMessage: 'Sesión iniciada',
      errorMessage: 'Error al iniciar sesión',
    },
    logout: 'Cerrar sesión',
    register: {
      title: 'Registrarse',
      createNew: 'Crear una cuenta nueva',
      successMessage: 'Cuenta creada',
      errorMessage: 'Error al crear cuenta',
    },
    forgotPassword: {
      title: '¿Olvidaste la contraseña?',
      successMessage:
        'Te hemos enviado a tu correo las instrucciones para restablecer tu contraseña.',
      errorMessage: 'Error al restablecer contraseña',
      submit: 'Recuperar contraseña',
      description:
        'Por favor ingresa tu correo electronico y recibiras las instrucciones para recuperar tu contraseña',
    },
    inputs: {
      name: 'Nombre',
      lastname: 'Apellido',
      password: 'Contraseña',
      confirmPassword: 'Confirmar contraseña',
      email: 'Correo eléctronico',
    },
    errorResponse: {
      password: {
        minLength: 'La contraseña debe tener como minimo 8 caracteres',
        minOneNumber: 'La contraseña debe tener al menos 1 número',
        minOneLetter: 'La contraseña debe tener al menos 1 letra',
      },
    },
  },
  resetPassword: {
    title: 'Restablecer contraseña',
    description: 'Ingresa tu nueva contraseña',
    successMessage: 'Tu contraseña fue cambiada exitosamente',
    errorMessage: 'Error al cambiar contraseña',
    expiredToken: 'El token expiró. Por favor genera un nuevo token',
  },
  errorTryLater:
    'Lo sentimos, ocurrio un error. Por favor intentalo de nuevo mas tarde.',
  instructions: {
    title: 'Instrucciones',
    rules: {
      general: [
        'El juego consiste en predecir los resultados de cada Fecha de un torneo.',
        'El jugador que mas puntos sume en la totalidad de partidos de la fecha es el ganador.',
        'Se pueden predecir los resultados de cada partido hasta 5 minutos antes que inicie el partido.',
        'Prediccion exacta suma 2 puntos.',
        'Predicción acertada suma 1 punto.',
        'Si algún partido del fixture en curso se suspende y se posterga a una fecha distinta al último partido programado de la fecha, el partido se descartará y no sumará puntos en el fixture, independientemente del resultado parcial.',
      ],
    },
  },
  support: {
    title: 'Contacto',
  },
  home: {
    fixtureSection: {
      buttonBuy: 'Confirmar Resultados',
    },
    buyModal: {
      title: 'Comprar Fixture',
      description:
        'Elegi tu metodo de pago para comprar tu fixture y participar de la fecha',
      confirmButton: 'Comprar ahora',
      cancelButton: 'Cancelar',
      collapsable: [
        {
          key: '1',
          label: 'Mercado Pago (Solo para Argentina)',
        },
        {
          key: '2',
          label: 'Transferencia (Solo para Argentina)',
        },
        {
          key: '3',
          label: 'Paypal',
        },
      ],
    },
    alertResultsError: {
      title: 'Error en tus resultados',
      description: 'Porfavor completa todos los resultados para continuar',
    },
  },
  confirmResults: {
    title: 'Confirmar Resultados',
    description:
      'Porfavor revisa tus resultados, una vez que el fixture se envie no podras realizar cambios',
    button: 'Armar Fixture',
    success: {
      title: 'Resultados Confirmados',
      description:
        'Ya estas participando de la fecha con tu fixture, buena suerte!',
    },
    error: {
      title: 'Error al crear el fixture',
    },
    alert: {
      description: 'Inicia sesión o Registrate para confirmar tu fixture',
    },
  },
  userTickets: {
    title: 'Mis Fixtures',
    modal: {
      title: 'Mis resultados',
    },
  },
  userProfile: {
    title: 'Mi Perfil',
    button: 'Editar Usuario',
    error: 'Error al editar los datos. Porfavor intentelo nuevamente mas tarde',
    success: 'Datos actualizado exitosamente',
  },
};

export default writingES;
