import React, { useRef } from 'react';
import styles from './ticket.module.css';
import { Column, Flex } from '../../../utils/layout';
import TourFixtureSection from './components/tourFixtureSection/TourFixtureSection';
import TournamentName from './components/tournamentName/TournamentName';
import FixtureName from './components/fixtureName/FixtureName';
import RoundNames from './components/roundNames/RoundNames';
import { RoundAttributes } from '../../../types/round.types';
import FixtureTitleItem from './components/fixtureTitleItem/FixtureTitleItem';
import { validateStartDate } from '../../../utils/hooks/handleDate';
import MatchItem from './components/matchItem/MatchItem';
import {
  SimplePrediction,
  TOnChangePredictionProps,
} from '../../screens/home/components/fixtureSection/FixtureSection';

type TicketProps = {
  tournamentName?: string;
  fixtureName?: string;
  onlyView?: boolean;
  isFixtureFinishedOpen?: boolean;
  isGolden?: boolean;
  rounds?: RoundAttributes[];
  roundSelected?: RoundAttributes;
  predictions?: SimplePrediction[];
  onChangeRoundId?: (roundId?: number) => void;
  onChangePrediction?: ({
    increase,
    matchId,
    team,
  }: TOnChangePredictionProps) => void;
};
const Ticket: React.FC<TicketProps> = ({
  tournamentName,
  fixtureName,
  onlyView,
  isFixtureFinishedOpen,
  isGolden,
  rounds,
  roundSelected,
  predictions,
  onChangeRoundId,
  onChangePrediction,
}) => {
  const refTournamentChangeName = useRef(null);
  const refFixtureChangeName = useRef(null);

  const isRoundDateValidate = validateStartDate(roundSelected?.startDate);
  const matches = roundSelected?.matches;

  return (
    <div className={styles.container}>
      <Column className={`${styles.header} ${isGolden && styles.goldenHeader}`}>
        <Flex className={styles.containerTournamentAndFixture}>
          <TournamentName
            refTournamentChangeName={refTournamentChangeName}
            tournamentName={tournamentName}
            isGolden={isGolden}
            onlyView={onlyView}
          />
          <div
            className={`${styles.divid} ${isGolden && styles.dividerGolden}`}
          ></div>

          <FixtureName
            refFixtureChangeName={refFixtureChangeName}
            fixtureName={fixtureName}
            isGolden={isGolden}
            onlyView={onlyView}
          />
        </Flex>

        {rounds && rounds.length > 1 ? (
          <RoundNames
            rounds={rounds}
            isGolden={isGolden}
            onChangeRoundId={onChangeRoundId}
            roundIdSelected={roundSelected?.id}
          />
        ) : null}

        <FixtureTitleItem
          isStarted={!isRoundDateValidate}
          isGolden={isGolden}
        />
      </Column>

      {matches?.length ? (
        <Column className={styles.containerMatches}>
          {matches.map((m, i) => (
            <MatchItem
              key={`pred-${i}`}
              prediction={predictions?.find((p) => p.matchId === m.id)}
              match={m}
              onChangePrediction={onChangePrediction}
              isGolden={isGolden}
              onlyView={onlyView}
            />
          ))}
        </Column>
      ) : null}

      {!onlyView && (
        <TourFixtureSection
          ref1={refTournamentChangeName}
          ref2={refFixtureChangeName}
          isFixtureFinishedOpen={isFixtureFinishedOpen}
        />
      )}
    </div>
  );
};

export default Ticket;
