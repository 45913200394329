import React from 'react';
import { StatusFixture } from '../../../types/fixture.types';
import { Column } from '../../../utils/layout';
import useClientStore from '../../clientStore/useClientStore';
import { useGetFixtureNames } from '../../serverStore/queries';

import ItemList from './components/itemList/ItemList';
import styles from './stylesModal.module.css';
import { DarkModal } from '../../commons';

export type FixtureListResponse = {
  name: string;
  id: number;
  status: StatusFixture;
};

interface IModalProps {
  open?: boolean;
  onClose?: () => void;
}

const FixtureListModal: React.FC<IModalProps> = ({ open, onClose }) => {
  const {
    dispatch,
    fixtureSelected: { tournamentId, fixtureId },
  } = useClientStore();
  const { data: fixtureNames } = useGetFixtureNames(tournamentId);
  const onSelectedItem = (id: number) => {
    dispatch({ type: 'SET_FIXTURE_ID', payload: id });
  };

  return (
    <DarkModal open={open} onClose={onClose} title='Lista de Fixtures'>
      <Column className={styles.content}>
        {fixtureNames?.map((f) => (
          <ItemList
            key={f.id}
            status={f.status}
            name={f.name}
            onSelected={() => onSelectedItem(f.id)}
            isSelected={fixtureId === f.id}
          />
        ))}

        {fixtureNames?.length && fixtureNames?.length > 1 && (
          <span className={styles.description}>
            Presiona sobre el Fixture para cambiar
          </span>
        )}
        {!fixtureNames?.length ? (
          <p className={styles.emptyText}>No se encontraron fixtures..</p>
        ) : null}
      </Column>
    </DarkModal>
  );
};

export default FixtureListModal;
