import { useQuery } from '@tanstack/react-query';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';
import useClientStore from '../../../clientStore/useClientStore';
import {
  CreditsPacksId,
  MpPreferenceResponse,
} from '../../../../types/credit.types';

type MpPreferenceProps = {
  packId?: CreditsPacksId;
  creditAmount: number;
};
const getMpPreference = async ({
  creditAmount,
  packId,
}: MpPreferenceProps): Promise<MpPreferenceResponse> => {
  let queryString = '';
  if (creditAmount) queryString += `creditAmount=${creditAmount}&`;
  if (packId) queryString += `packId=${packId}&`;
  // Eliminar el último "&" si existe
  queryString = queryString.replace(/&$/, '');

  const response: MpPreferenceResponse = await axiosFetch(
    `/mercadopago/preference?${queryString}`,
  );
  return response;
};

export default function useGetMpPreference(params: MpPreferenceProps) {
  const { loggedUser } = useClientStore();
  const queryKey = ['mp-preference', params.creditAmount];
  if (params.packId) {
    queryKey.push(params.packId);
  }

  return useQuery({
    queryKey,
    queryFn: () => getMpPreference(params),
    enabled: !!loggedUser && !!params.creditAmount,
    staleTime: Infinity, // 5 minutos  - durante X minutos no vuelve a hacer el request cuando se monta de nuevo el componente a menos que cambien los datos de la query.
    gcTime: 30 * 60 * 1000, // 30 minutos - los datos en la cache se almacenan por x minutos despues se borran.
  });
}
