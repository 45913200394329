import React from 'react';
import { Column } from '../../../../../../utils/layout';
import styles from './historySection.module.css';
import TransactionTable from './components/TransactionTable';
import { useGetUserTransactions } from '../../../../../serverStore/queries';
import useClientStore from '../../../../../clientStore/useClientStore';
const HistorySection: React.FC = () => {
  const { loggedUser } = useClientStore();
  const { data: transactions } = useGetUserTransactions(loggedUser?.email);

  if (!transactions?.length) {
    return (
      <Column className={styles.container}>
        <span className={styles.title}>
          Actualmente no tienes transacciones registradas.
        </span>
      </Column>
    );
  }

  return (
    <Column className={styles.container}>
      <span className={styles.title}>
        Consulta tu historial de transacciones de créditos
      </span>
      <span className={styles.description}>
        En la siguiente tabla podrás ver el registro completo de todas las
        recargas y retiros de créditos realizados con tu cuenta.
      </span>

      <TransactionTable transactions={transactions} />
    </Column>
  );
};

export default HistorySection;
