import React, { useState } from 'react';

import styles from './tournamentName.module.css';
import CustomTooltip from '../../../customTooltip/CustomTooltip';
import CupIcon from '../../../../../assets/CupIcon';
import { capitalizeFirstLetter } from '../../../../../utils';
import TournamentsListModal from '../../../../modals/home/TournamentsListModal';

interface ITournamentNameProps {
  refTournamentChangeName: React.MutableRefObject<null>;
  tournamentName?: string;
  isGolden?: boolean;
  onlyView?: boolean;
}

const TournamentName: React.FC<ITournamentNameProps> = ({
  refTournamentChangeName,
  tournamentName,
  isGolden,
  onlyView,
}) => {
  const [openTournamentsListModal, setOpenTournamentsListModal] =
    useState(false);
  return (
    <>
      <CustomTooltip
        title='Presiona para cambiar de torneo'
        disabled={onlyView}
      >
        <div
          ref={refTournamentChangeName}
          className={`${styles.tournamentText} ${
            isGolden && styles.tournamentTextGolden
          } ${onlyView && styles.tournamentTextOnlyView}`}
          onClick={() => {
            if (onlyView) return;
            setOpenTournamentsListModal(true);
          }}
        >
          <CupIcon />
          <p className={styles.tournamentName}>
            {tournamentName
              ? capitalizeFirstLetter(tournamentName)
              : 'Seleccionar Torneo'}{' '}
          </p>
          <CupIcon />
        </div>
      </CustomTooltip>
      {openTournamentsListModal ? (
        <TournamentsListModal
          open={openTournamentsListModal}
          onClose={() => setOpenTournamentsListModal(false)}
        />
      ) : null}
    </>
  );
};

export default TournamentName;
