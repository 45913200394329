import React from 'react';
import useWriting from '../../../copywriting/useWriting';
import { Column, Flex } from '../../../utils/layout';
import BannerSection from '../../../utils/layout/components/bannerSection/BannerSection';
import SupportForm from './components/supportForm/SupportForm';
import styles from './support.module.css';

type socialMedia = 'fb' | 'ig' | 'x' | 'tt';

const socialMediaUrlBook: { [key in socialMedia]: string } = {
  fb: 'https://www.facebook.com/profile.php?id=61556748842145',
  ig: 'https://www.instagram.com/fixtuwin',
  x: 'https://x.com/Fixtuwin',
  tt: 'https://www.tiktok.com/@fixtuwinok?lang=es',
};

const Support: React.FC = () => {
  const writing = useWriting();

  const handleIconClick = (name: socialMedia) => {
    window.open(socialMediaUrlBook[name], '_blank');
  };

  return (
    <Column className={styles.containerSupport}>
      <BannerSection nameSection={writing.support.title} />
      <div className={styles.container}>
        <span className={styles.title}>Estamos Aquí Para Ayudarte.</span>
        <span className={styles.description}>
          Puedes contactarnos de la forma que prefieras. Nuestro horario de
          atención es de lunes a viernes, de 8:00 a 18:00 horas.
        </span>

        <Column className={styles.card} gap={24}>
          <span className={styles.subTitle}>Redes Sociales</span>
          <Flex className={styles.containerSocial}>
            <Column onClick={() => handleIconClick('x')}>
              <i className='fa-brands fa-square-x-twitter'></i>
            </Column>
            <Column onClick={() => handleIconClick('ig')}>
              <i className='fa-brands fa-square-instagram'></i>
            </Column>

            <Column onClick={() => handleIconClick('fb')}>
              <i className='fa-brands fa-square-facebook'></i>
            </Column>
            <Column onClick={() => handleIconClick('tt')}>
              <i className='fa-brands fa-tiktok'></i>
            </Column>
          </Flex>
        </Column>

        <Column className={styles.card} gap={24}>
          <span className={styles.subTitle}>Correo Electrónico</span>
          <Flex
            className={styles.containerEmail}
            onClick={() =>
              window.open('mailto:soporte@fixtuwin.com.ar', '_self')
            }
          >
            <i className='fas fa-envelope'></i>
            <p>soporte@fixtuwin.com.ar</p>
          </Flex>
        </Column>

        <Column className={styles.card} gap={24}>
          <span className={styles.subTitle}>Formulario de Contacto</span>
          <SupportForm />
        </Column>
      </div>
    </Column>
  );
};

export default Support;
