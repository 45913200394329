import { Checkbox, Tour, TourProps } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './tourFixtureSection.module.css';
import { Flex } from '../../../../../utils/layout';

type PropsDescription = {
  description: string;
  checked: boolean;
  onCheckboxClick: (c: boolean) => void;
};
const formatDesciption = ({
  checked,
  description,
  onCheckboxClick,
}: PropsDescription) => {
  return (
    <div className={styles.checkboxItem}>
      <p>{description}</p>
      <Flex
        className={styles.checkbox}
        onClick={() => onCheckboxClick(!checked)}
      >
        <Checkbox checked={checked} />

        <p>No volver a mostrar</p>
      </Flex>
    </div>
  );
};

const mask = {
  style: {
    boxShadow: 'inset 0 0 15px #fff',
  },
  color: 'rgba(0, 0, 0, 0.7)',
};

interface ITourFixtureSectionProps {
  ref1: React.MutableRefObject<null>;
  ref2: React.MutableRefObject<null>;
  isFixtureFinishedOpen?: boolean;
}

const TourFixtureSection: React.FC<ITourFixtureSectionProps> = ({
  ref1,
  ref2,
  isFixtureFinishedOpen,
}) => {
  const [checked, setChecked] = useState(false);
  const [openTour, setOpenTour] = useState<boolean>(false);

  const onCheckboxClick = (c: boolean) => {
    setChecked(c);
  };

  const onFinish = () => {
    if (checked) {
      localStorage.setItem('showedTourFixtures', JSON.stringify(true));
      setOpenTour(false);
    }
  };

  useEffect(() => {
    if (isFixtureFinishedOpen) {
      return setOpenTour(false);
    }

    const storageShowedTourFixtures =
      localStorage.getItem('showedTourFixtures');
    if (!storageShowedTourFixtures) {
      setOpenTour(true);
    }
  }, [isFixtureFinishedOpen]);

  const steps: TourProps['steps'] = [
    {
      title: '¿ Como cambiar de Torneo ?',
      description: formatDesciption({
        checked,
        description:
          'Presiona sobre el nombre del torneo para cambiar el actual.',
        onCheckboxClick,
      }),

      target: () => ref1.current,
      nextButtonProps: { children: <>Continuar</> },
      mask,
    },
    {
      title: '¿ Como cambiar de Fecha ?',
      description: formatDesciption({
        checked,
        description:
          'Presiona sobre el nombre de la fecha para cambiar la actual.',
        onCheckboxClick,
      }),
      target: () => ref2.current,
      prevButtonProps: { children: <>Volver</> },
      nextButtonProps: { children: <>Finalizar</> },
      mask,
    },
  ];
  return (
    <Tour
      open={openTour}
      onClose={() => setOpenTour(false)}
      steps={steps}
      onFinish={onFinish}
    />
  );
};

export default TourFixtureSection;
